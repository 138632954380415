import { GoogleMap, InfoWindowF, PolygonF, useJsApiLoader } from '@react-google-maps/api'
import React, { useState } from 'react'
import './MapWithPolygon.css'

const libraries = ['places']


function MapWithPolygon({ polygon }) {

  // convert polygon data to lat lng object
  const transformedData = polygon.parcel_polygon.map(([lng, lat]) => ({ lat, lng }));
  const transformSetBackData = polygon.setback_polygon.length !== 0 && polygon.setback_polygon.map(([lng, lat]) => ({ lat, lng }));


  // Show popup when hovering over the polygon
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);


  const [infoWindowOpen2, setInfoWindowOpen2] = useState(false);
  const [infoWindowPosition2, setInfoWindowPosition2] = useState(null);

  const handleMouseOver = (e) => {
    setInfoWindowOpen(true);
    setInfoWindowPosition(e.latLng); // Set position of InfoWindow
  };

  const handleMouseOut = () => {
    setInfoWindowOpen(false);
  };

  const handleMouseOver2 = (e) => {
    setInfoWindowOpen2(true);
    setInfoWindowPosition2(e.latLng); // Set position of InfoWindow
  };

  const handleMouseOut2 = () => {
    setInfoWindowOpen2(false);
  };

  // set map size
  const mapContainerStyle = {
    height: '100%',
    width: '100%'
  }

  // Needed to first call to render map
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: libraries,
  });

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  if (loadError) {
    return <div>{loadError.message}</div>
  }

  return (
    <GoogleMap
      center={transformedData[0]}
      zoom={18}
      mapContainerStyle={mapContainerStyle}
      options={
        {
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
        }
      }
    >
      <PolygonF
        paths={transformedData}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        options={{
          fillColor: 'black',
          fillOpacity: 0.5,
          strokeColor: 'blue',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />

      {
        transformSetBackData && (<PolygonF
          paths={transformSetBackData}
          onMouseOver={handleMouseOver2}
          onMouseOut={handleMouseOut2}
          options={{
            fillColor: 'green',
            fillOpacity: 0.5,
            strokeColor: 'blue',
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />)
      }

      {infoWindowOpen && (
        <InfoWindowF position={infoWindowPosition}>
          <div>
            <h6>Parcel</h6>
            {/* <p>This is where you can display information about the polygon.</p> */}
          </div>
        </InfoWindowF>
      )}

      {infoWindowOpen2 && (
        <InfoWindowF position={infoWindowPosition2}>
          <div>
            <h6>Setback</h6>
            {/* <p>This is where you can display information about the polygon.</p> */}
          </div>
        </InfoWindowF>
      )}
    </GoogleMap>
  )
}

export default MapWithPolygon