import React, { useRef } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import './GoogleAddressInput.css';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';

const libraries = ["places"];

function GoogleAddressInput({ address, setAddress, handleKeyPress }) {
  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: libraries
  });

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (loadError) {
    return <div>{loadError}</div>;
  }

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place && place.formatted_address) { // Ensure place and formatted_address are valid
      setAddress(place.formatted_address); // Set the address state with the formatted address
    }
  };

  return (
    <Autocomplete
      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
      onPlaceChanged={onPlaceChanged}
      className='google_address'
    >
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-text">Address</InputLabel>
        <OutlinedInput
          id="outlined-adornment-text"
          type="text"
          label="Address"
          name="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)} // Update address state on input change
          onKeyDown={handleKeyPress} // Handle key press events
          sx={{borderRadius:"5px 0 0 5px", height:"2.5rem"}}
        />
      </FormControl>
      {/* <input
        onKeyPress={handleKeyPress}
        type="text"
        className='google_address_input'
        placeholder="Enter your address"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      /> */}
    </Autocomplete>
  );
}

export default GoogleAddressInput;
