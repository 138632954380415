import React, { useContext, useEffect, useRef, useState } from 'react'
import './UserNav.css'
import { useNavigate } from 'react-router-dom'
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import IQList from '../util_component/iq_list/IQList';
import { AuthContext } from '../auth/AuthenticationContext';
import IQLoading from '../util_component/iq_loading/IQLoading';

function UserNav({ tab }) {

  const navigate = useNavigate();

  const { logOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [profileMenu, setProfileMenu] = useState(false);
  const profile_tabs = [
    {
      text: "Subscription",
      action: null
    },
    {
      text: "FAQ",
      action: null
    },
    {
      text: "Favorites",
      action: () => navigate("/favorite")
    },
    {
      text: "Settings",
      action: () => navigate("/settings/personal")
    },
    {
      text: "Help",
      action: null
    },
    {
      text: "Logout",
      action: async () => {
        setLoading(true);
        await logOut();
      }
    }
  ]

  // handle outside click
  const profileTabs = useRef(null);
  const handleClickOutside = (event) => {
    if (profileTabs.current && !profileTabs.current.contains(event.target)) {
      setProfileMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const copilotNavigation = ()=> {
    const chatId = sessionStorage.getItem("chatID");
    if(chatId){
      navigate(`/copilot?chat=${chatId}`)
    }
    else{
      navigate("/copilot")
    }
  }

  return (
    <nav className='user_nav'>
      {/* Logo iqland */}
      <img src='/images/logo.svg' alt='IQLAND' style={{ width: "130px", height: "32px" }} />


      <div style={{display:"flex", alignItems:"center", gap:"2.5rem"}}>
        {/* tabs */}
        <ul className='user_nav__tabs'>
          <li onClick={() => navigate("/")}>Home</li>
          <li className={tab === "c" ? "user_nav__active_tab" : null} onClick={() => copilotNavigation()}>Copilot</li>
          <li className={tab === "d" ? "user_nav__active_tab" : null} onClick={() => navigate("/dashboard")}>Dashboard</li>
          <li className={tab === "r" ? "user_nav__active_tab" : null} onClick={() => navigate("/reports")}>Reports</li>
        </ul>

        {/* profile area */}
        <div ref={profileTabs} className='user_nav_profile_area' >

          <div className='user_nav_profile' onClick={() => setProfileMenu(!profileMenu)}>
            <img src='/images/default_profile.jpg' alt='profile' />
            <KeyboardArrowDownOutlined />
          </div>
          {
            profileMenu && <IQList items={profile_tabs} />
          }
        </div>
      </div>


      {
        loading && <IQLoading message={"Logging out..."} />
      }
    </nav>
  )
}

export default UserNav