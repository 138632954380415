import { BathtubOutlined, BedOutlined, DirectionsCarFilledOutlined, FavoriteBorderOutlined, SquareFootOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import React from 'react'
import './SinglePlanCard.css'

function SinglePlanCard({plan}) {
    return (
        <div className='singleplancard'>
            
            <img loading='lazy' src={plan.image_link} alt='plan' className='singleplancard__image' />
            
            <div className='singleplancard__info'>
                <div className='code_line'>
                    <h6>{plan.plan_number}</h6>
                    <div className='heart_vision'>
                        <VisibilityOffOutlined />
                        <FavoriteBorderOutlined />
                    </div>
                </div>
                <ul className='features_line'>
                    <li><BedOutlined/>{plan.bedrooms_count}</li>
                    <li><BathtubOutlined/>{plan.bathrooms_count}</li>
                    <li><SquareFootOutlined/> {plan.area_total}</li>
                    {plan.area_garage ? <li><DirectionsCarFilledOutlined/></li>:<li></li>}
                </ul>

            </div>
        </div>
    )
}

export default SinglePlanCard