import React from 'react'

function DashboardHeader() {
  return (
    <header className='dashboard__header'>
      <div><a href='https://www.iqland.ai/'><img className='header_logo' src='/logo2.svg' alt='Logo'/></a></div>
      <div className='dashboard__profile_section'>
        <img className='button' src='/images/dashboard/heart.svg' alt='heart'/>
        <img className='button' src='/images/dashboard/setting.svg' alt='setting'/>
        <img className='profile_img' src='/images/copilot/default_profile.png' alt='profile'/>
      </div>
    </header>
  )
}

export default DashboardHeader