import React, { useState } from 'react';
import "./FormPopup.css";
import axios from 'axios';
const FormPopup = ({ handleClose, show }) => {

    const showHideClassName = show ? "modal2 display-block" : "modale2 display-none";

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [occupation, setOccupation] = useState('');

    const [showThankYou, setShowThankYou] = useState('display-none');
    const [hideForm, setHideForm] = useState('display-block');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${process.env.REACT_APP_BACK_END_END_POINT}/join_waitlist`, { email: email, first_name: firstName, last_name: lastName, occupation: occupation });
            // setMessage(response.data.message);
            setShowThankYou('display-block');
            setHideForm('display-none');
            // setEmail('');
        } catch (error) {
            // setMessage('Failed to submit email. Please try again later.');
            console.error('Error submitting email:', error);
        }
        // console.log(message);
    };




    return (
        <div className={showHideClassName}>
            <section className="modal-main1">

                <div className={hideForm}>
                    <div className='popup-new'>
                        <span className="close" onClick={handleClose}>&times;</span>
                        <div className='left-area'>

                            <div className='logo'><img src={process.env.PUBLIC_URL + "/logo2.svg"} alt='logo'/></div>
                            <h1>Join the Waitlist</h1>
                            <p>By joining the waitlist, you will soon be a part of our land development revolution.  Check your email and spam folders for correspondence from our team.</p>
                        </div>
                        <div className='right-area'>
                            <form onSubmit={handleSubmit}>
                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='First Name' required />
                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Last Name' required />
                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' required />
                                <input type="text" value={occupation} onChange={(e) => setOccupation(e.target.value)} placeholder='Occupation' required />
                                <button type='submit'>Join</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={showThankYou}>
                    <div className='popup-new'>
                        <span className="close" onClick={handleClose}>&times;</span>
                        <div className='left-area'>
                            <div className='ty-image'>
                                <img src={process.env.PUBLIC_URL + "/thankyou.png"} alt='thank you'/>
                            </div>
                            <h1 style={{ textAlign: 'center' }}>Thank you!</h1>
                            <p style={{ textAlign: 'center' }}>By joining the waitlist, you will soon be a part of our land development revolution.  Check your email and spam folders for correspondence from our team.</p>
                            <button onClick={handleClose}>Welcome!</button>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};
export default FormPopup;