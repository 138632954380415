import React, { useEffect, useState } from 'react';
import './DashboardMain.css'
import { GraphCard1 } from '../../components/dashboard_components/GraphCard';
import { conData, equityData, vacData } from '../../components/dashboard_components/graphData';
import PlanFoundCard from '../../components/dashboard_components/PlanFoundCard';
import FilterCard from './FilterCard';
import ZoningSummary from '../../components/dashboard_components/ZoningSummary';
import ParcelMap from '../../components/dashboard_components/ParcelMap';
import ParcelInformation from '../../components/dashboard_components/ParcelInformation';
// import BuildQuality from '../../components/dashboard_components/BuildQuality';
import axios from 'axios';
import IQLoading from '../../components/util_component/iq_loading/IQLoading';

function DashboardMain() {
    const vacColor = { top: "rgba(249, 81, 41, 0.8)", bottom: "rgba(231, 149, 60, 0.49)" }
    const conColor = { top: "rgba(141, 169, 241, 0.8)", bottom: "rgba(141, 169, 241, 0.5)" }
    const equityColor = { top: "rgba(71, 223, 207, 0.8)", bottom: "rgba(71, 223, 207, 0.5)" }
    const projectColor = { top: "rgba(255, 49, 0, 0.8)", bottom: "rgba(255, 49, 0, 0.5)" }

    const [graphValues, setGraphValues] = useState({});
    const [plans, setPlans] = useState([]);
    const [zoningInfo, setZoningInfo] = useState({});

    const [loading, setLoading] = useState(true);

    const [selectedMedal, setSelectedMedal] = useState("BRONZE");

    async function fetchData(dashboardData) {
        const response = await axios.post(`https://api.iqland.ai/demo_populateDashBoard/`, dashboardData)
        // console.log(response);
        const data = response.data.api_resp;
        setGraphValues(data.KPI_vals)
        setPlans(data.archi_plans);
        setZoningInfo(data.zoning_info);
        setLoading(false)

    }

    useEffect(() => {

        try {
            const dashboardData = {
                "build_quality": "BRONZE",
                "story_count": 1,
                "bed_count": 1,
                "bath_count": 2,
                "min_area": 2500,
                "max_area": 4500,
                "acquisition_cost": 75000
            }
            fetchData(dashboardData)
        }
        catch (error) {
            // console.log(error)
        }
    }, [])

    const handleMedalClick = async (medal) => {
        setSelectedMedal(medal);
        setLoading(true)
        try {

            const response = await axios.post(`${process.env.REACT_APP_BACK_END_END_POINT}/demo_change_buildQuality/`, {
                "build_quality": selectedMedal
            }, { withCredentials: true })
            const data = response.data.api_resp;
            setGraphValues(data.KPI_vals)
            setPlans(data.archi_plans);
            setZoningInfo(data.zoning_info);
            setLoading(false)
        }

        catch (error) {
            // console.log(error.data);
            setLoading(false)
        }


    }

    return (
        <div className='dashboardmain'>

            <div className='dashboardmain__sec1'>
                <h5>533 Boulevard Ct</h5>
                <button style={{ width: "150px", height: "38px" }} className='btn-primary'>Share Report</button>
            </div>
            <div className='dashboardmain__sec2'>
                <GraphCard1 data={vacData} title="Value after Completion (VAC)" amount={graphValues.vac} graphColor={vacColor} keyName="VAC" />
                <GraphCard1 data={conData} title="Construction Costs" amount={graphValues.construction_cost} graphColor={conColor} keyName="Construction" />
                <GraphCard1 data={equityData} title="Equity" amount={graphValues.equity} graphColor={equityColor} keyName="Equity" />
                <GraphCard1 data={conData} title="Total Project Cost" amount={graphValues.price_psqft_finished} graphColor={projectColor} keyName="Construction" />
            </div>
            <div className='dashboardmain__sec3'>
                <PlanFoundCard plans={plans} />
                <FilterCard filterFunction={fetchData} medal={selectedMedal} />
            </div>
            <div className='dashboardmain__sec4'>
                <ParcelInformation />
                <ParcelMap />
                <ZoningSummary data={zoningInfo} />

                <div className='buildquality'>
                    <b>Select Build Quality</b>
                    <div className='buildquality__medal' style={selectedMedal === "GOLD" ? { backgroundColor: "var(--light-green-clr)" } : null} onClick={() => handleMedalClick("GOLD")}>
                        <img src='/images/dashboard/gold_medal_icon.png' alt='Gold' />
                        <div className='text'>
                            <h5>GOLD $$$</h5>
                            <p>Highly customized, high end finishes, highly skilled trades.</p>
                        </div>
                    </div>
                    <div className='buildquality__medal' style={selectedMedal === "SILVER" ? { backgroundColor: "var(--light-green-clr)" } : null} onClick={() => handleMedalClick("SILVER")}>
                        <img src='/images/dashboard/silver_medal_icon.png' alt='Silver' />
                        <div className='text'>
                            <h5>SILVER $$</h5>
                            <p>Custom features, mid to high end finishes.</p>
                        </div>
                    </div>
                    <div className='buildquality__medal' style={selectedMedal === "BRONZE" ? { backgroundColor: "var(--light-green-clr)" } : null} onClick={() => handleMedalClick("BRONZE")}>
                        <img src='/images/dashboard/bronze_medal_icon.png' alt='Bronze' />
                        <div className='text'>
                            <h5>BRONZE $</h5>
                            <p>Builder grade materials and finishes.</p>
                        </div>
                    </div>
                </div>


            </div>

            {loading && <IQLoading message="Setting up dashboard....." />}
        </div>
    )
}

export default DashboardMain