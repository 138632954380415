import React from 'react'
import './IQTextboxLabel.css'

function IQTextboxLabel({value, setText, placeholder, label, disabled}) {


    return (
        <div className='iq_textbox_label'>
            <label htmlFor="name">{label}</label>
            <input type="text" disabled={disabled} placeholder={placeholder} value={value} onChange={(e) => setText(e.target.value)} id="name" />
        </div>
    )
}

export default IQTextboxLabel