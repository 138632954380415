import React from 'react'
import { MapContainer, Marker, Popup, SVGOverlay, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

function ParcelMap() {
  const position = [51.505, -0.09]
  const bounds = [
    [51.49, -0.08],
    [51.5, -0.06],
  ]
  return (
    <div className='parcelmap parcelmap__container'>
      <b>Parcel Map</b>
      <MapContainer style={{width:"100%", height:"100%"}} center={position} zoom={13} scrollWheelZoom={false}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <SVGOverlay attributes={{ stroke: 'red' }} bounds={bounds}>
      <circle r="10" cx="10" cy="10" fill="red" />
      <text x="50%" y="50%" stroke="white">
        text
      </text>
    </SVGOverlay>
  </MapContainer>,
    </div>
  )
}

export default ParcelMap