import React from 'react'
import './ChatBotPreCard.css'

function ChatBotPreCard({title, subTitle, deactivate, handleClick}) {
  return (
    <div
    className={deactivate===true?' chat_bot_pre_card chat_bot_pre_card__deactivate':'chat_bot_pre_card'}
    onClick={deactivate===true?null:handleClick}
    >
        <h4>{title}</h4>
        <h6>{subTitle}</h6>
    </div>
  )
}

export default ChatBotPreCard