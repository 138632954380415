import React, { useEffect, useRef, useState } from 'react'
import './CommercialDemo.css'
import { AttachmentOutlined, ChatOutlined, Send } from '@mui/icons-material'
import IQButton from '../../components/util_component/iq_button/IQButton'
import CommercialDemoChat from './commercial_demo_chat/CommercialDemoChat'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { preQueries } from '../../data/PreQueriesData'
import ChatBotPreCard from './chat_bot_pre_card/ChatBotPreCard'
import IQSpinner from '../../components/util_component/iq_spinner/IQSpinner'



function CommercialDemo() {


  const token = "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";
  const dataToSend = { "inputs": "setbacks" };
  const [loading, setLoading] = useState(false);

  // // // list of chat
  // const [chatList, setChatList] = useState(chatListSampleData);

  // for address
  const [address, setAddress] = useState("");


  useEffect(() => {
    const url = `${process.env.REACT_APP_WARM_APP_END_POINT}/models/mixedbread-ai/mxbai-embed-large-v1`;
    try {
      axios.post(url, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

      });

      // console.log(response);
    }
    catch (error) {
      // console.log(error.response.data);
    }

  })


  const navigate = useNavigate();

  // buttons
  const handleDashboardClick = (e) => {
    e.preventDefault();
    navigate('/demo/dashboard');
  }

  // handling chat text change
  const [chatText, setChatText] = useState("");
  const handleChatTextChange = (e) => {
    setChatText(e.target.value);
  }


  // full chat
  const [fullChat, setFullChat] = useState([]);

  // // scoll to loading
  // const loadingRef = useRef(null);


  // scroll to bottom
  const endRef = useRef(null);
  const scrollToBottom = () => {
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [fullChat])





  //  it is for finding sources and name
  function getNameSourcePairs(obj) {
    const result = [];
    const seen = new Set();

    function recursiveSearch(currentObj) {
      if (typeof currentObj !== 'object' || currentObj === null) {
        return;
      }

      for (const key in currentObj) {
        if (key === 'name' && currentObj['source'] !== undefined) {
          const name = currentObj['name'];
          const source = currentObj['source'];

          // Create a unique identifier for name-source pairs to avoid duplicates
          const identifier = `${name}:${source}`;
          if (!seen.has(identifier)) {
            result.push({ name, source });
            seen.add(identifier);
          }
        }

        if (typeof currentObj[key] === 'object') {
          recursiveSearch(currentObj[key]);
        }
      }
    }

    recursiveSearch(obj);
    return result;
  }



  // handling send button click
  const handleSendButtonClick = async () => {
    setLoading(true);
    const userInput = chatText + ", " + address;
    setChatText("");
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_END_END_POINT}/get_response_from_LLM/`,
        {
          "user_message": userInput
        }
      )
      setLoading(false);

      response.data.address && response.data.address !== "NA" ? setAddress(response.data.address) : setAddress("");

      // save full chat
      const sampleChat = response.data.results ? {
        "user_message": chatText,
        "bot_message": {
          "answer": response.data.llm_response,
          "source": getNameSourcePairs(response.data)
        }
      }
        :
        {
          "user_message": chatText,
          "bot_message": {
            "answer": response.data.llm_response,
            "source": [
            ]
          }
        }
      setFullChat([...fullChat, sampleChat])

      // const chatItem = {
      //   "id": fullChat.length + 1,
      //   "title": userInput
      
      // }

      // setChatList((prevChat) => [...prevChat, chatItem]);
      // console.log(chatList)
    }
    catch (error) {
      // save full chat
      // const errorChat = {
      //   "user_message": error,
      //   "bot_message": {
      //     "answer": error,
      //     "source": [
      //     ]
      //   }
      // }
      // setFullChat([...fullChat, errorChat])
      setLoading(false);
    }


  }

  const handlePressEnter = (event) => {
    if (event.key === 'Enter' && loading === false && chatText.trim() !== "") {
      handleSendButtonClick();
    }
  }

  return (
    <div className='commercial_demo'>
      <div className='commercial_demo__side_bar'>
        <div className='commercial_demo__new_chat_text_area'>
          <img className='commercial_demo__profile_image' src='/images/default_profile.jpg' alt='profile' />
          <div className='commercial_demo__new_chat_text'>New Parcel Chat</div>
          <ChatOutlined style={{ color: "var(--primary-clr)" }} />
        </div>

        <ul className='commercial_demo__chat_list'>
          {/* {
            chatList.map((chat) => (
              <li key={chat.id}><TypeWriter isNewMessage={true} text={chat.title}/></li>
            ))
          } */}

        </ul>

      </div>
      <div className='commercial_demo__content'>
        <div className='commercial_demo__top_buttons'>
          <IQButton text="Dashboard" handleClick={handleDashboardClick} />
          <IQButton text="Logout" disable={true} />
        </div>



        <div className='commercial_demo__chat_area'>
          {
            fullChat.length === 0 ?
              <div className='commercial_demo__title_area'>
                <img src='/images/copilot/logo1.png' alt='logo' />
                <h4>Let's get started</h4>
              </div>
              :
              null
          }
          {
            fullChat.length === 0 ?

              <div className='commercial_demo__pre_queries'>

                <ChatBotPreCard deactivate={true} title={preQueries[0].title} subTitle={preQueries[0].content} />
                <ChatBotPreCard title={preQueries[1].title} subTitle={preQueries[1].content} handleClick={() => setChatText(preQueries[1].content)} />
                <ChatBotPreCard deactivate={true} title={preQueries[2].title} subTitle={preQueries[2].content} />
                <ChatBotPreCard deactivate={true} title={preQueries[3].title} subTitle={preQueries[3].content} />
                <ChatBotPreCard deactivate={true} title={preQueries[5].title} subTitle={preQueries[5].content} />
                <ChatBotPreCard title={preQueries[4].title} subTitle={preQueries[4].content} handleClick={() => setChatText(preQueries[4].content)} />
              </div>

              :
              null
          }

          <div className='commercial_demo__chat_text_area'>

            {
              fullChat ? fullChat.map((chat, index) => {
                return <CommercialDemoChat key={index} userText={chat.user_message} botResponse={chat.bot_message} />
              }) : null
            }

            {
              loading ? <IQSpinner/> : null
            }

            {/* end of message for loading*/}
            <div style={{marginTop:"64px"}} ref={endRef}></div>

          </div>
          <div className='commercial_demo__chat_bar'>
            <AttachmentOutlined className='attachment' />
            <input disabled={loading} type="text" value={chatText} onKeyDown={handlePressEnter} onChange={handleChatTextChange} placeholder='Enter address or parcel number' />
            {
              loading ?
                <img style={{ backgroundColor: "gray" }} src='/images/spinner.gif' alt='loading' className='send_button' />
                :
                <Send className='send_button' onClick={loading || chatText.trim() === "" ? null : handleSendButtonClick} style={loading || !chatText ? { backgroundColor: "gray" } : null} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommercialDemo