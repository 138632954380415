import React, { useState } from 'react'

function FilterCard({ filterFunction, medal }) {


  const [bedroom, setBedRoom] = useState(5);
  const [bathroom, setBathRoom] = useState(2);

  const [filter, setFilter] = useState({

    "build_quality": medal,
    "story_count": 1,
    "bed_count": bedroom,
    "bath_count": bathroom,
    "min_area": 2500,
    "max_area": 4500,
    "acquisition_cost": 75000

  })

  const handleFilterDataChange = async (e) => {

    try {
      if (filter.acquisition_cost !== "" && filter.min_area !== "" && filter.max_area !== "") {
        filterFunction(filter)
      }
    }
    catch (error) {
      // console.log(error.data);
    }
  }


  return (
    <div className='commercial_demo_filter'>
      <div className='commercial_demo_filter__title'>Filters</div>
      <div className='commercial_demo_filter__filter_area'>
        <div className='commercial_demo_filter__asset_value'>
          <div className='commercial_demo_filter__label'>
            Asset Value
          </div>
          <div>
            <input type="text" placeholder='Asset Value' onChange={e => setFilter({ ...filter, acquisition_cost: parseFloat(e.target.value) })} value={filter.acquisition_cost} />
          </div>
        </div>
        <div>
          <div className='commercial_demo_filter__label'>
            Total Heated Area (Sq Ft.)
          </div>
          <div className='commercial_demo_filter__heated_area'>
            <input type="text" placeholder='min' onChange={e => setFilter({ ...filter, min_area: parseFloat(e.target.value) })} value={filter.min_area} />
            <input type="text" placeholder='max' onChange={e => setFilter({ ...filter, max_area: parseFloat(e.target.value) })} value={filter.max_area} />
          </div>
        </div>
        <div className='commercial_demo_filter__rooms'>
          <div className='commercial_demo_filter__room'>
            <div className='commercial_demo_filter__label'>Bedrooms</div>
            <ul>
              <li onClick={() => setBedRoom(1)} className={bedroom === 1 ? "active_filter_btn" : null}>1</li>
              <li onClick={() => setBedRoom(2)} className={bedroom === 2 ? "active_filter_btn" : null}>2</li>
              <li onClick={() => setBedRoom(3)} className={bedroom === 3 ? "active_filter_btn" : null}>3</li>
              <li onClick={() => setBedRoom(4)} className={bedroom === 4 ? "active_filter_btn" : null}>4</li>
              <li onClick={() => setBedRoom(5)} className={bedroom === 5 ? "active_filter_btn" : null}>5+</li>
            </ul>
          </div>
          <div className='commercial_demo_filter__room'>
            <div className='commercial_demo_filter__label'>Bathrooms</div>
            <ul >
              <li onClick={() => setBathRoom(1)} className={bathroom === 1 ? "active_filter_btn" : null} >1</li>
              <li onClick={() => setBathRoom(2)} className={bathroom === 2 ? "active_filter_btn" : null} >2</li>
              <li onClick={() => setBathRoom(3)} className={bathroom === 3 ? "active_filter_btn" : null} >3</li>
              <li onClick={() => setBathRoom(4)} className={bathroom === 4 ? "active_filter_btn" : null} >4</li>
              <li onClick={() => setBathRoom(5)} className={bathroom === 5 ? "active_filter_btn" : null} >5+</li>
            </ul>
          </div>

        </div>
        <div className='commercial_demo_filter__plan_type'>
          <div className='commercial_demo_filter__label'>Plan Type</div>
          <div className='commercial_demo_filter__checkboxes'>
            <input type='checkbox' name='single' />
            <label >Single Family Homes</label>
          </div>
          <div className='commercial_demo_filter__checkboxes'>
            <input type='checkbox' name='duplex' />
            <label >Duplex</label>
          </div>
          <div className='commercial_demo_filter__checkboxes'>
            <input type='checkbox' name='triplex' />
            <label >Triplex</label>
          </div>
          <div className='commercial_demo_filter__checkboxes'>
            <input type='checkbox' name='multi' />
            <label >Multifamily</label>
          </div>
        </div>

        <div>
          <div className='commercial_demo_filter__label'>
            Foot Print
          </div>
          <div>
            <div className='commercial_demo_filter__label' style={{ fontSize: "10px", marginLeft: "8px" }}>
              Width (feet)
            </div>
            <div className='commercial_demo_filter__heated_area'>
              <input type="text" placeholder='min' onChange={e => setFilter({ ...filter, min_area: parseFloat(e.target.value) })} value={filter.min_area} />
              <input type="text" placeholder='max' onChange={e => setFilter({ ...filter, max_area: parseFloat(e.target.value) })} value={filter.max_area} />
            </div>
          </div>
          <div>
            <div className='commercial_demo_filter__label' style={{ fontSize: "10px", marginLeft: "4px" }}>
              Height (feet)
            </div>
            <div className='commercial_demo_filter__heated_area'>
              <input type="text" placeholder='min' onChange={e => setFilter({ ...filter, min_area: parseFloat(e.target.value) })} value={filter.min_area} />
              <input type="text" placeholder='max' onChange={e => setFilter({ ...filter, max_area: parseFloat(e.target.value) })} value={filter.max_area} />
            </div>
          </div>
        </div>

        <div className='commercial_demo_filter__story_count'>
          <div className='commercial_demo_filter__label'>
            Story Count
          </div>
          <div>
            <input type="text" placeholder='Story Count' onChange={e => setFilter({ ...filter, story_count: parseFloat(e.target.value) })} value={filter.story_count} />
          </div>
        </div>
      </div>
      <button className='commercial_demo_filter__apply_btn' onClick={() => handleFilterDataChange()}>Apply</button>
    </div>
  )
}

export default FilterCard