import React, { useEffect, useState } from 'react'
import './Favorites.css'
import PlanNew from '../dashboard_new/component/PlanNew'
import UserNav from '../../components/user_nav/UserNav'
import { getFavoritePlans } from '../../components/user_service/UserService';
import IQSpinner from '../../components/util_component/iq_spinner/IQSpinner';
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading';

function Favorites() {

    // favoirte plans
    const [favoirtPlans, setFavoritePlans] = useState();

    function isFavorite(plan_id) {
        return favoirtPlans.some(favPlan => favPlan.id === plan_id);
    }

    async function getFavoritePlansOfUser() {
        const response = await getFavoritePlans();
        setFavoritePlans(response.data);
    }

    useEffect(() => {
        try {
            const response = getFavoritePlansOfUser();
            // console.log(response);
            setFavoritePlans(response.data);
        }
        catch (error) {
            // console.log(error);
        }
    }, [])

    return (
        <div className='favorites'>
            <UserNav />
            <div className='favorites__body'>
                <div className='favorites__container'>
                    <div className='favorites__title'>
                        <h6>Favorites</h6>
                        <span>{favoirtPlans ? `(${favoirtPlans.length})`: <IQSpinner/>}</span>
                    </div>
                    <div className='favorites__plans'>
                        {
                            favoirtPlans ? favoirtPlans.map((plan, index) => (
                                <PlanNew key={index} plan={plan} fav={isFavorite(plan.id)}/>
                            ))
                                :
                                <div style={{display:"flex", gap:"1rem", justifyContent:"space-between"}}>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                    <LoadingSkeleton/>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Favorites