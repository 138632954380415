import React, { useState } from 'react'
import './ProfInfo.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import IQTextboxLabel from '../../components/util_component/iq_textbox_label/IQTextboxLabel'

function ProfInfo() {

    const [text, setText] = useState('');

    return (
        <div className='prof_info'>
            <UserNav />
            <div className='prof_info__container'>
                <SettingSideBar tab={"2"} />
                <div className='prof_info__main'>
                    <div className='prof_info__part'>
                        <h5>Professional Information</h5>
                        <div className='prof_info__inputs'>
                            <div className='prof_info__inputs_sets'>
                                <IQTextboxLabel label='Industry' value={text} setText={() => setText()} placeholder={"First Name"} />
                                <IQTextboxLabel label='Company' value={text} setText={() => setText()} placeholder={"Last Name"} />
                            </div>
                            <div className='prof_info__inputs_sets'>
                                <IQTextboxLabel label='Job Title' value="jhone@gmail.com" disabled={true} />
                                <IQTextboxLabel label='Years of Experience ' value={text} setText={() => setText()} placeholder={"First Name"} />
                            </div>
                            <div className='prof_info__inputs_sets'>
                                <IQTextboxLabel label='Home built develop per/year' value="jhone@gmail.com" disabled={true} />
                                <IQTextboxLabel label='License Number' value={text} setText={() => setText()} placeholder={"First Name"} />
                            </div>
                        </div>
                        <button className='prof_info__btn'>Update</button>
                    </div>

                   
                    <div className='prof_info__part'>
                        <h5>About Me / Company</h5>
                        <div className='prof_info__inputs'>
                            <div className='prof_info__inputs_sets'>
                                <IQTextboxLabel label='Short Bio or Company Description' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='prof_info__btn' disabled>Update</button>
                    </div>

                   

                </div>
            </div>
        </div>
    )
}

export default ProfInfo