import React from 'react';
// import './Login.css';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerWithEmail } from '../../components/auth/AuthService';
import { useState } from 'react';
import IQLoading from '../../components/util_component/iq_loading/IQLoading';
import { useNavigate } from 'react-router-dom';
import IQInformationPop from '../../components/util_component/iq_information_pop/IQInformationPop';
import VerifyEmail from './VerifyEmail';

function Register() {

    const [verifyEmail, setVerifyEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('Registering...');
    const [infoPop, setInfoPop] = useState(false);
    const [infoPopMsg, setInfoPopMsg] = useState('');
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string()
            .min(6, 'must be at least 6 characters')
            .matches(/[A-Z]/, 'must contain at least one uppercase letter')
            .matches(/[a-z]/, 'must contain at least one lowercase letter')
            .matches(/[0-9]/, 'must contain at least one number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'must contain at least one symbol')
            .required('Password is required'),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                setLoading(true);
                await registerWithEmail(values.first_name, values.last_name, values.email, values.password);
                setLoading(false);
                navigate('/login');
            } catch (error) {
                setErrors({ api: error.message });
                setLoading(false);
                setInfoPopMsg(error.message);
                if(error.message.error.contain("verify")){
                    setVerifyEmail(true);
                }
                setInfoPop(true);

            } finally {
                setSubmitting(false);
            }
        }
    })
    const [isValidating, setIsValidating] = useState(false);

    const handlePasswordChange = (event) => {
        formik.handleChange(event);
        setIsValidating(true);
    };

    const isRequirementMet = (requirement) => {
        return requirement ? '✅' : '❌';
    };

    const passwordRequirements = [
        { label: 'At least 6 characters', isValid: formik.values.password.length >= 6 },
        { label: 'At least one uppercase letter', isValid: /[A-Z]/.test(formik.values.password) },
        { label: 'At least one lowercase letter', isValid: /[a-z]/.test(formik.values.password) },
        { label: 'At least one number', isValid: /[0-9]/.test(formik.values.password) },
        { label: 'At least one symbol', isValid: /[!@#$%^&*(),.?":{}|<>]/.test(formik.values.password) },
    ];


    return (
        <section className='login_page register_bg'>
            <div className='login__card'>
                <h3 style={{ textAlign: 'center', marginBottom: '1em' }}>Register</h3>
                <form className='login__form' onSubmit={formik.handleSubmit}>
                    <div>
                        <label>First Name</label>
                        <input type='text'
                            id='first_name'
                            placeholder='Enter your first name'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.first_name} />
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <div className='login__form_error'>{formik.errors.first_name}</div>
                        ) : null}
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input type='text'
                            id='last_name'
                            placeholder='Enter your last name'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.last_name} />

                        {formik.touched.last_name && formik.errors.last_name ? (
                            <div className='login__form_error'>{formik.errors.last_name}</div>
                        ) : null}
                    </div>
                    <div>
                        <label>Email</label>
                        <input type='text'
                            id='email'
                            placeholder='Enter your email'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className='login__form_error'>{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div>
                        <label>Password</label>
                        <input type='password'
                            id='password'
                            placeholder='****************'
                            onBlur={formik.handleBlur}
                            onChange={handlePasswordChange}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className='password-requirements'>
                                {passwordRequirements.map((req, index) => (
                                    <div key={index} className={`requirement ${req.isValid ? 'valid' : 'invalid'}`}>
                                        <span>{isRequirementMet(req.isValid)}</span> {req.label}
                                    </div>
                                ))}
                            </div>
                        ) : null}

                    </div>
                    <button type="submit" style={{ display: 'inline-block', marginBottom: '2em' }} className='btn-primary'>Register</button>
                </form>
                <div className='login__logo_area'>
                    <img className='logo' src='/logo2.svg' alt='IQLAND Logo' />
                </div>

                <div className='login__dont_have'>Already, have an account? <Link to='/login' className='link'>Sign in</Link></div>
            </div>
            
            {
                // loading pop
                loading && <IQLoading message={loadingMsg}/>

            }
            {
                // information pop
                infoPop && <IQInformationPop message={infoPopMsg} setVisibility={setInfoPop}/>
            }
            {

                verifyEmail && <VerifyEmail email={formik.values.email} setVisibility={setVerifyEmail}/>
            }

        </section>
    )
}

export default Register