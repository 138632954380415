import './VerifyEmail.css'
import { verifyEmail } from '../../components/auth/AuthService'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

function VerifyEmail({ email, setVisibility }) {

    const navigate = useNavigate()

    // OTP state
    const [otp, setOtp] = useState('')
    // OTP error information
    const [otpError, setOtpError] = useState('')

    const handleClick = async () => {
        
        //must 6 chars
        if (otp.length !== 6) { 
            setOtpError('Invalid OTP')
        } else {
            setOtpError('')
            try {
                await verifyEmail(email, otp);
                // console.log(response);
                setVisibility(false)
                window.location.href = '/login'
            }
            catch (err) {
                setOtpError(err.message)
            }
        }
    }
    return (
        <div className='verify_email'>
            <div className='verify_email__container'>
                <h5>Verify Email</h5>
                <input type='text' placeholder='Enter OTP' value={otp} onChange={(e) => {setOtp(e.target.value); setOtpError("")}} />
                <p className='verify_email__error'>{otpError}</p>
                <button className='btn-primary' onClick={handleClick}>Submit</button>
            </div>
        </div>
    )
}

export default VerifyEmail