import React from 'react'
import "./NewFilter.css"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Slider } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material';

function NewFilter({ handleClose, handleFilterButton, filterData, setFilterData }) {

    // active button style
    const activeButtonStyle = {
        backgroundColor: "var(--primary-clr)",
        color: "#fff"
    }


    // list of constant values
    // const list_plan_type = ["Single-Family Homes", "Stand-Alone Garages", "Multi-Family Homes", "Other"]
    const list_plan_type = ["Single Family", "Duplex", "Multi Family", "Other"]
    const list_style = ["Modern Farmhouse", "Country", "Barndominium", "New American", "Scandinavian", "Modern", "Cottage", "Contemporary", "Southern", "Acadian", "Adobe", "A-Frame", "Beach", "Bungalow", "Cabin", "Cap cod", "Carriage", "Coastal Contemporary", "Colonial", "European"]
    const list_garage = ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"]
    const list_foundation = ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"]
    const list_wall = ["Block", "2x4"]
    const list_bedrooms = ["1", "2", "3", "4", "5", "6", "7", "8+"]
    const list_bathrooms = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "5+"]
    const list_floors = ["1", "2", "3"]
    const list_vehicles = ["1", "2", "3"];


    // Clear all filter
    const handleClearAll = () => setFilterData({
        "title": "",
        "architectural_style": ["Modern Farmhouse", "Country", "Barndominium", "New American", "Scandinavian", "Modern", "Cottage", "Contemporary", "Southern", "Acadian", "Adobe", "A-Frame", "Beach", "Bungalow", "Cabin", "Cap cod", "Carriage", "Coastal Contemporary", "Colonial", "European"],
        "area_total_min": 10,
        "area_total_max": 10000,
        "width_min": 10,
        "width_max": 500,
        "height_min": 10,
        "height_max": 100,
        "depth_min": 10,
        "depth_max": 500,
        "stories": ["1"],
        "cars_capacity": ["2"],
        "foundation": ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"],
        "exterior_wall_type": ["2x4"],
        "garage_type": ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"],
        "units": ["Single Family", "Duplex", "Multi Family", "Other"],
        "bedrooms": ["4"],
        "bathrooms": ["3"]
    })


    // common slide data change function
    const handleSliderChange = (field) => (event, newValue) => {
        setFilterData(prevState => ({
            ...prevState,
            [`${field}_min`]: newValue[0],
            [`${field}_max`]: newValue[1]
        }));
    };

    // common checkbox changes
    const handleCheckboxChange = (event, arrayName) => {
        const { name, checked } = event.target;
        setFilterData(prevState => {
            const newArray = checked
                ? [...prevState[arrayName], name]
                : prevState[arrayName].filter(item => item !== name);

            return {
                ...prevState,
                [arrayName]: newArray
            };
        });
    };


    const min_max_slider_style = {
        width: "calc(100% - 1.22rem)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.66rem",
        color: '#126342', // Custom color
        '& .MuiSlider-thumb': {
            backgroundColor: '#126342', // Thumb color
        },
        '& .MuiSlider-track': {
            backgroundColor: 'var(primary-clr)', // Track color
        },
        '& .MuiSlider-rail': {
            backgroundColor: '#F1F1F', // Rail color
        },
    }




    return (
        <div className='new_filter'>

            <div className='new_filter__title_area'>
                <h3>All Filters</h3>
                <img src='/images/dashboard_new/close_icon.svg' onClick={() => handleClose()} alt='x' />
            </div>
            <div className='new_filter__filters'>

                <div className='filter_input_with_icon'>
                    <h6>Enter Keyword</h6>
                    <div className='filter_input_with_button'>
                        <input type='text' placeholder='Search' value={filterData.title} onChange={(e) => setFilterData({ ...filterData, title: e.target.value })} />
                        {/* <div className='keyword_search_btn'><img src='/images/dashboard_new/search_icon.svg' alt='filter' /></div> */}
                    </div>
                </div>

                {/* <div className='filter_normal_input'>
                    <h6>Land Acquisition Cost</h6>
                    <input type='text' placeholder='Acquisition Cost' value={cost} onChange={(e) => setCost(e.target.value)} />
                </div> */}

                {/* <div className='filter_dropdown'>
                    <h6>Building Quality</h6>
                    <select value={quality} onChange={(e) => setQuality(e.target.value)}>
                        <option value='BRONZE'>Economy</option>
                        <option value='SILVER'>Standard</option>
                        <option value='GOLD'>Premium</option>
                    </select>
                </div> */}

                

                <div className='filter_min_max'>
                    <h6>Total Area (sqft)</h6>
                    <div>
                        <Slider
                            sx={min_max_slider_style}
                            value={[filterData.area_total_min, filterData.area_total_max]}
                            onChange={handleSliderChange('area_total')}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            disableSwap
                            min={10}
                            max={10000}
                        />
                        <div className='filter_min_max__values'>
                            <span>10 sqft</span>
                            <span>10000 sqft</span>
                        </div>
                    </div>
                    <div className='filter_min_max__inputs'>
                        <div className='filter_min_max__label'>Min:</div>
                        <input
                            type='text'
                            placeholder='Min'
                            value={filterData.area_total_min}
                            onChange={(e) => setFilterData({ ...filterData, area_total_min: e.target.value })}
                        />
                        <div className='filter_min_max__label'>Max:</div>
                        <input
                            type='text'
                            placeholder='Max'
                            value={filterData.area_total_max}
                            onChange={(e) => setFilterData({ ...filterData, area_total_max: e.target.value })}
                        />
                    </div>
                </div>

                <div className='filter_with_count_list'>
                    <h6>Bedrooms</h6>
                    <ul>
                        {
                            list_bedrooms.map((c, index) => {
                                const isActive = filterData.bedrooms.includes(c);
                                return (
                                    <li
                                        key={index}
                                        style={isActive ? activeButtonStyle : {}}
                                        onClick={() => {
                                            const newBedrooms = isActive
                                                ? filterData.bedrooms.filter(bedroom => bedroom !== c)
                                                : [...filterData.bedrooms, c];
                                            setFilterData({ ...filterData, bedrooms: newBedrooms });
                                        }}
                                    >{c}</li>
                                );
                            })
                        }
                    </ul>


                </div>

                <div className='filter_with_count_list'>
                    <h6>Bathrooms</h6>
                    <ul>
                        {
                            list_bathrooms.map((c, index) => {
                                const isActive = filterData.bathrooms.includes(c);
                                return (
                                    <li
                                        key={index}
                                        style={isActive ? activeButtonStyle : {}}
                                        onClick={() => {
                                            const newBathrooms = isActive
                                                ? filterData.bathrooms.filter(bathroom => bathroom !== c)
                                                : [...filterData.bathrooms, c];
                                            setFilterData({ ...filterData, bathrooms: newBathrooms });
                                        }}
                                    >{c}</li>
                                );
                            })
                        }
                    </ul>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_plan_type.svg' alt='foot print' />
                                <h5>Plan Type</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {<FormGroup>
                                    {
                                        list_plan_type.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.units.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "units")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_foot_print.svg' alt='foot print' />
                                <h5>Footprint</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem"
                            }}
                        >
                            <div className='filter_min_max'>
                                <h6>Width (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.width_min, filterData.width_max]}
                                        onChange={handleSliderChange('width')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={500}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>500 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.width_min}
                                        onChange={(e) => setFilterData({ ...filterData, width_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.width_max}
                                        onChange={(e) => setFilterData({ ...filterData, width_max: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='filter_min_max'>
                                <h6>Depth (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.depth_min, filterData.depth_max]}
                                        onChange={handleSliderChange('depth')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={500}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>500 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.depth_min}
                                        onChange={(e) => setFilterData({ ...filterData, depth_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.depth_max}
                                        onChange={(e) => setFilterData({ ...filterData, depth_max: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='filter_min_max'>
                                <h6>Height (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.height_min, filterData.height_max]}
                                        onChange={handleSliderChange('height')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={100}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>100 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.height_min}
                                        onChange={(e) => setFilterData({ ...filterData, height_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.height_max}
                                        onChange={(e) => setFilterData({ ...filterData, height_max: e.target.value })}
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_floors_icon.svg' alt='foot print' />
                                <h5>Floors</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    <FormGroup>
                                        {list_floors.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.stories.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "stories")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_home_styles.svg' alt='foot print' />
                                <h5>Styles</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    <FormGroup>
                                        {list_style.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.architectural_style.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "architectural_style")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_cars_icon.svg' alt='foot print' />
                                <h5>No of Vehicles</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    <FormGroup>
                                        {list_vehicles.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.cars_capacity.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "cars_capacity")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_garage_icon.svg' alt='foot print' />
                                <h5>Garage Type</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    <FormGroup>
                                        {list_garage.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.garage_type.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "garage_type")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_foundation_icon.svg' alt='foot print' />
                                <h5>Foundation</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    list_foundation.map(style => (
                                        <FormControlLabel
                                            sx={{ marginLeft: "0", display: "block" }}
                                            key={style}
                                            control={
                                                <div className='acc_data_item'>
                                                    <div className='text_with_count'>
                                                        <div className='text'>{style}</div>
                                                        {/* <div className='count'>(23)</div> */}
                                                    </div>
                                                    <div>
                                                        <Checkbox
                                                            checked={filterData.foundation.includes(style)}
                                                            onChange={(e) => handleCheckboxChange(e, "foundation")}
                                                            name={style}
                                                            sx={{
                                                                '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                '& .MuiSvgIcon-root': { fontSize: 24 }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    ))
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_walls_icon.svg' alt='foot print' />
                                <h5>Exterior Walls</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {<FormGroup>
                                    {
                                        list_wall.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.exterior_wall_type.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "exterior_wall_type")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>



            </div>
            <div className='new_filter_buttons_area'>
                <button className='secondary_btn' onClick={() => { handleClearAll()}}>Clear All</button>
                <button className='primary_btn' onClick={() => handleFilterButton(filterData)}>Apply</button>

            </div>
        </div>
    )
}

export default NewFilter