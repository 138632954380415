import { ResponsiveStream } from '@nivo/stream'
import React from 'react'

export function GraphCard1({ data, title, amount, graphColor, keyName }) {
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(number);
    }

    return (
        <div className='graph_card'>
            <div className='graph_card__texts'>
                <h6>{title}</h6>
                <h6 style={{ color: "green", fontWeight: "bold" }}>{formatCurrency(amount)}</h6>
            </div>

            <div className='graph_card__graph'>
                <ResponsiveStream
                    data={data}
                    keys={[
                        keyName,
                    ]}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: 36,
                        truncateTickAt: 0
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: -40,
                        truncateTickAt: 0
                    }}
                    enableGridY={false}
                    curve="basis"
                    offsetType="none"
                    colors={{ scheme: 'set1' }}
                    fillOpacity={0.65}

                    borderColor={{
                        theme: 'background'  // Use the theme's background color for the border
                    }}
                    fill={[
                        {
                            match: '*',
                            id: 'gradient'
                        }
                    ]}
                    defs={[
                        {
                            id: 'gradient',
                            type: 'linearGradient',
                            colors: [
                                { offset: 0, color: graphColor.top }, // Orange shade with opacity 0.8
                                { offset: 100, color: graphColor.bottom } // Darker shade with opacity 0.5
                            ]
                        }
                    ]}
                    dotSize={7}
                    dotColor={{ from: 'color' }}
                    dotBorderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.7
                            ]
                        ]
                    }}
                    tooltip="null"
                />
            </div>
        </div>
    );
}

