import React from 'react'
import '../../components/dashboard_components/DashboardComponents.css'
import DashboardHeader from '../../components/dashboard_components/DashboardHeader'
import DashboardSideBar from '../../components/dashboard_components/DashboardSideBar'
// import Footer from '../../components/footer/Footer'
import DashboardMain from './DashboardMain'

function Dashboard() {
  return (
    <>
      <div className='dashboard'>
        <DashboardHeader />
        <div className='dashboard__main'>
          <DashboardSideBar/>
          <div className='dashboard__main__content'>
            <DashboardMain/>
          </div>
        </div>
      </div>

      {/* <Footer/> */}
    </>
  )
}

export default Dashboard