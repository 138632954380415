export const vacData = [
    {
      "VAC": 100,
    },
    {
      "VAC": 180,
    },
    {
      "VAC": 141,
    },
    {
      "VAC": 113,
    },
    {
      "VAC": 14,
    },
    {
      "VAC": 88,
    },
    {
      "VAC": 105,
    },
    {
      "VAC": 80,
    },
    {
      "VAC": 158,
    }
  ];


export const conData = [
    {
        "Construction": 100,
    },
    {
        "Construction": 10,
    },
    {
        "Construction": 95,
    },
    {
        "Construction": 122,
    },
    {
        "Construction": 58,
    },
    {
        "Construction": 66,
    },
    {
        "Construction": 123,
    },
    {
        "Construction": 25,
    },
]

export const equityData = [
    //some random values
    {
        "Equity": 100,
    },
    {
        "Equity": 10,
    },
    {
        "Equity": 95,
    },
    {
        "Equity": 122,
    },
    {
        "Equity": 58,
    },
    {
        "Equity": 66,
    }
]