import React, { useEffect, useRef, useState } from 'react'
import './CoReports.css'
import UserNav from '../../components/user_nav/UserNav'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import IQList from '../../components/util_component/iq_list/IQList';
import { getReports } from '../../components/user_service/UserService';
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading';

function CoReports() {

    const [keyword, setKeyword] = useState("");


    // actions list
    const action = [
        {
            icon: "view_icon.svg",
            text: "View",
            action: null
        },
        {
            icon: "download.svg",
            text: "Download",
            action: null
        },
        {
            icon: "share_icon.svg",
            text: "Share",
            action: null
        },
        {
            icon: "delete_icon.svg",
            text: "Delete",
            action: null
        }

    ]

    const [actionVisibleIndex, setActionVisibleIndex] = useState(null);

    // handle outside click
    const actionsRef = useRef(null);
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setActionVisibleIndex(null);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(number);
    }
    const header_style = {
        backgroundColor: "var(--primary-clr)",
        borderRadius: "0.75rem 0.75rem 0 0",
        color: "white",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        textTransform: "uppercase"
    }

    const body_style = {
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.00938em",
        textAlign: "left",
        backgroundColor: "#F9F9F9",
        color: "#4C495A"
    }

    const [reports, setReports] = useState();

    async function getAllReports() {
        const response = await getReports();
        return setReports(response.data);
    }

    useEffect(() => {
        getAllReports();
    }, [])


    return (
        <div className='co_reports'>
            <UserNav tab="r" />
            <div className='co_reports__search_area'>
                <h6>Reports</h6>
                <div className='co_reports__input_with_icon'>
                    <div className='co_reports__input_with_button'>
                        <input type='text' placeholder='Search' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                        <div className='co_reports__keyword_search_btn'><img src='/images/dashboard_new/search_icon.svg' alt='filter' /></div>
                    </div>
                </div>
            </div>

            <TableContainer component={Paper} sx={{ padding: "1.38rem 7rem 1rem 7rem", height:"100%" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={header_style}>
                            <TableCell className='co_reports__head_cell' >Address </TableCell>
                            <TableCell className='co_reports__head_cell' align="center">Project cost</TableCell>
                            <TableCell className='co_reports__head_cell' align="center">Equity</TableCell>
                            <TableCell className='co_reports__head_cell' sx={{ width: "4rem" }} align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        reports
                            ?
                            <TableBody >
                                {reports.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={body_style}
                                    >
                                        <TableCell className='co_reports__body_cell' component="th" scope="row">
                                            {row.address}
                                        </TableCell>
                                        <TableCell className='co_reports__body_cell' align="center">{row.kpis ? formatCurrency(row.kpis.total_project_cost): 'NA'}</TableCell>
                                        <TableCell className='co_reports__body_cell' align="center">{row.kpis ? formatCurrency(row.kpis.equity): 'NA'}</TableCell>
                                        <TableCell className='co_reports__body_cell' align="center">
                                            <div style={{ position: "relative" }} ref={actionsRef} onClick={() => setActionVisibleIndex(index)}><MoreVertOutlined className='co_reports__more_actions' /></div>
                                            {actionVisibleIndex === index && <IQList items={action} />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            :
                            <LoadingSkeleton/>
                    }
                </Table>
            </TableContainer>
        </div>
    )
}

export default CoReports