import React, { useEffect, useState } from 'react'
import ChatFormat from '../../components/chatbot_msg_components/ChatFormat'
import AddressSingleForm from '../../components/chatbot_msg_components/AddressSingleForm'
import ChatPlanCards from '../../components/chatbot_msg_components/ChatPlanCards'
import axios from 'axios';
import AddressFullForm from '../../components/chatbot_msg_components/AddressFullForm';
import ChatSource from '../../components/chatbot_msg_components/ChatSource';
import ChatbotInputBar from '../../components/chatbot/chatbot_input_bar/ChatbotInputBar';
import MapWithPolygon from '../../components/google_map/MapWithPolygon';
import GoogleAddressInput from '../../components/google_map/GoogleAddressInput';
import CountdownTimer from '../../components/countdown_timer/CountdownTimer';


function TestPage() {



  const [plans, setPlans] = useState([]);
  const apiData = {
    "build_quality": "GOLD",
    "story_count": 1,
    "bed_count": 1,
    "bath_count": 2,
    "min_area": 2500,
    "max_area": 4500,
    "acquisition_cost": 75000
  }

  async function fetchData(apiData) {
    const response = await axios.post(`${process.env.REACT_APP_BACK_END_END_POINT}/demo_populateDashBoard/`, apiData)
    setPlans(response.data.api_resp.archi_plans)
  }

  // useEffect(() => {
  //   try {
  //     fetchData(apiData)
  //   }
  //   catch (error) {
  //     console.log(error.response.error)
  //   }
  // })

  const sources = [
    {
      title: "Airbnb",
      link: "https://www.airbnb.com."
    },
    {
      title: "Crunch",
      link: "https://www.airbnb.com."
    }
  ]

  const message = "## Table Example\n\n| Building Type | Lot Area |\n|----------|----------|\n| Detached House   | 5500 Feet   |\n| Detached House   | 5500 Feet   |\n| Detached House   | 5500 Feet   |"

  const [messages, setMessages] = useState([]);

  const handleSendMessage = (message) => {
    setMessages([...messages, message]);
  };

  const msgnew = "The setbacks for 8226 E 34TH ST, TULSA, OK, based on the RS-3 zoning code (Residential Single-family 3) are as follows:\n\n- **Minimum Rear Setback**: 20 feet\n- **Minimum Interior Side Setback**: 5 feet\n- **Minimum Non-Arterial Street Setback**: 25 feet\n- **Minimum Arterial Street Setback**: 35 feet\n\nPlease note that for detached houses and duplexes on corner lots, the minimum side street setback along a non-arterial street may be reduced to 15 feet, provided that the minimum setback for street-facing garage doors is 20 feet or 20 feet from the back of the sidewalk, whichever is greater."
  const location = {
    lat: 32.912787,
    lng: -96.8320538
  }

  const polygonCoords = [
    { lat: 32.9129, lng: -96.83223 }, // Top left
    { lat: 32.9129, lng: -96.83221 }, // Top middle
    { lat: 32.91298, lng: -96.83221 }, // Top right
    { lat: 32.91298, lng: -96.83199 }, // Middle right
    { lat: 32.91274, lng: -96.83199 }, // Bottom right
    { lat: 32.91274, lng: -96.83205 }, // Bottom middle
    { lat: 32.91279, lng: -96.83205 }, // Bottom left
    { lat: 32.91279, lng: -96.83223 }, // Middle left
    { lat: 32.9129, lng: -96.83223 }  // Close the loop
  ];


  const targetDate = '2024-07-15T00:00:00';

  return (
    <div className='test_page'>

      {/* <ChatFormat msg={msgnew} /> */}
      <CountdownTimer targetDate={targetDate} />

      {/* <AddressSingleForm />
      <ChatPlanCards plans={plans} />
      <AddressFullForm />
      <ChatSource sources={sources} /> */}


      {/* <div className="chat-window">
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className="message">
              {msg}
            </div>
          ))}
        </div>
        <ChatbotInputBar onSendMessage={handleSendMessage} />
      </div> */}

      {/* <div style={{ width: "320px", height: "256px" }}>
        <MapWithPolygon location={location} polygon={polygonCoords} />
      </div>
      <GoogleAddressInput /> */}

    </div>
  )
}

export default TestPage