import axios from "axios"
import { getCsrfToken } from "../user_service/UserService";

const API_URL = process.env.REACT_APP_BACK_END_END_POINT;


// Helper function to handle errors
function handleError(error) {

    // Handle server error
    if (error.response) {
        console.error('Server responded with an error:', error.response);
        throw new Error(error.response.data.error);
    }
    // handle network error
    else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('Check your internet connection');
    }
    // handle any other error
    else {
        console.error('Error in setting up the request:', error.message);
        throw new Error(error.message);
    }
}

export async function getSession() {
    try {
        const response = await axios.get(`${API_URL}/session/`, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export async function login(username, password) {
    try {
        const response = await axios.post(`${API_URL}/login/`, {
            "username": username,
            "password": password
        }, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export async function verifyOtp(username, otp) {
    try {
        const response = await axios.post(`${API_URL}/verify-otp/`, {
            "otp": otp,
            "username": username
        }, { withCredentials: true });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export async function registerWithEmail(first_name, last_name, email, password) {
    try {
        const response = await axios.post(`${API_URL}/register/`, {
            "first_name": first_name,
            "last_name": last_name,
            "username": email,
            "password": password,
            "role": "Commercial",
            "is_verified": false
        });

        if (response.status === 409) { // 409 Conflict
            throw new Error("Email is already registered.");
        }
        return response.data;
    }
    catch (error) {
        handleError(error);
    }

}

export async function verifyEmail(email, otp) {
    try {
        const response = await axios.post(`${API_URL}/verify-email/`,
            {
                "username": email,
                "otp": otp
            },
            { withCredentials: true },


        );
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export async function logout() {
    try {
        const csrfToken = await getCsrfToken();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        await axios.post(`${API_URL}/logout/`, {}, { withCredentials: true, })
        
        // Remove all cookies
        document.cookie.split(";").forEach((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        });
        sessionStorage.removeItem('chatID')
        sessionStorage.removeItem('address')


    }
    catch (error) {
        handleError(error);
    }


}


export async function subscribePlan(plan) {
    try {
        const response = await axios.post(`${API_URL}/event/checkout/session/`, {
            "plan_type": plan
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}