import React, { useEffect, useRef, useState } from 'react';
import './CoMainArea.css';
import ChatbotInputBar from '../../../components/chatbot/chatbot_input_bar/ChatbotInputBar';
import NewChat from './NewChat';
import axios from 'axios';
import CoSingleChat from './CoSingleChat';
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { createChat, getChatById, nextChat } from '../../../components/user_service/UserService';
import IQLoading from '../../../components/util_component/iq_loading/IQLoading';

function CoMainArea({ onNewChat }) {

    // get tab from url
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tab = query.get("chat");


    // message input to handle pre cards click
    // share message between new chat area to input bar
    const [message, setMessage] = useState('');

    // loading
    const [loading, setLoading] = useState(false);

    // for address
    const [address, setAddress] = useState("");

    // screen loading
    const [screenLoading, setScreenLoading] = useState(true);

    // full chat
    const [fullChat, setFullChat] = useState([]);

    // get missing input
    const [missingInput, setMissingInput] = useState({});

    // move to chat
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChat = async () => {
            try {
                if (!tab) {
                    setScreenLoading(false);
                    setFullChat([]);
                    setAddress("NA")
                    sessionStorage.removeItem("chatID")
                } else {
                    const response = await getChatById(tab);
                    response.data.address && setAddress(response.data.address)
                    console.log("full chat", response)
                    setFullChat(response.data.messages);
                    setScreenLoading(false);
                    sessionStorage.setItem("chatID", tab)
                    if(response.data.address){
                        sessionStorage.setItem("address", response.data.address)
                    }
                    else{
                        
                        sessionStorage.setItem("address", "NA")
                    }
                }
            } catch (error) {
                console.error(error);
                setScreenLoading(false);
            }
        };
        fetchChat();
    }, [tab]); // Only run when `tab` changes

    // scroll to bottom
    const endRef = useRef(null);
    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [fullChat]);

    // handling send button click
    const handleSendButtonClick = async (msg) => {
        setLoading(true);
        const userInput = `${msg}, ${address && address !== "NA" && address}`;

        // Update UI with user's message immediately
        setFullChat(prevChats => [...prevChats, { sent_by: 'User', text: msg }]);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END_END_POINT}/get_response_from_LLM/`,
                { "user_message": userInput }
            );
            console.log("llm response", response.data);

            const llmResponse = response.data.llm_response;

            //getting missing input details to passs chat
            setMissingInput(response.data.missing_inputs);

            // Update UI with bot's response
            setFullChat(prevChats => [...prevChats, { sent_by: 'Bot', text: llmResponse }]);

            // Save the conversation if a tab is present
            if (tab) {
                await nextChat(tab, "User", msg);
                await nextChat(tab, "Bot", llmResponse);
            } else {
                let chatTitle = msg.substring(0, 25);
                let address = "NA"
                if (response.data.address && response.data.address !== "NA") {
                    chatTitle = response.data.address;
                    address = response.data.address;
                }
                // console.log(address)
                const createdMessage = await createChat(chatTitle, msg, address);
                const newChatId = createdMessage.data.chat_id;
                const date = new Date();
                const newChat = { chat_id: newChatId, chat_title: chatTitle, updated_at: date.toISOString()};
                onNewChat(newChat);
                // console.log(createdMessage.data)
                await nextChat(newChatId, "Bot", llmResponse)
                navigate(`/copilot?chat=${createdMessage.data.chat_id}`);
            }

            // Update address state if necessary
            setAddress(response.data.address && response.data.address !== "NA" ? response.data.address : "");

        } catch (error) {
            console.error("Error sending message:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='co_main_area'>
            <div className='co_main_area_chat_container'>
                <div className='co_main_area__chat_area'>
                    {/* Chat messages */}
                    {fullChat.length !== 0 ? (
                        fullChat.map((chat, index) => (
                            <CoSingleChat key={index} chat={chat} isLastMessage={fullChat.length -1 === index} missingInput={missingInput}/>
                        ))
                    ) : (
                        <NewChat setInput={setMessage}/>
                    )}

                    {loading && <IQSpinner />}

                    {fullChat.length !== 0 && (
                        <div style={{ height: "64px" }} ref={endRef}>
                            {/* This div represents the bottom of the chat area */}
                            &nbsp;
                        </div>
                    )}
                </div>
            </div>

            <div className='co_main_area__input_bar'>
                <ChatbotInputBar onSendMessage={handleSendButtonClick} isLoading={loading || screenLoading} message={message} setMessage={setMessage}/>
            </div>

            {/* {screenLoading && <IQLoading message="Fetching data..." />} */}
        </div>
    );
}

export default CoMainArea;
