import React, { useState } from 'react'
import IQButton from '../../components/util_component/iq_button/IQButton'
import './Pricing.css'
import NavBar from '../../components/navbar/NavBar'
import FormPopup from '../../components/modal/FormPopup';

function Pricing() {

    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }


    return (
        <>
            <NavBar handleVisiblity={handleModal} />
            <div className='pricing_packages'>
                <h4>Select the package that best fits your needs</h4>
                <div className='pricing_packages__packages'>
                    <div className='pricing_packages__package'>
                        <h1>Starter</h1>
                        <h1>$25<span className="pricing_packages__month">/month</span></h1>
                        <p>
                            Ideal for individual developers,
                            real estate agents and investors.
                            All the features all the time
                        </p>
                        <ul>
                            <li>75 Parcel Searches</li>
                        </ul>
                        <IQButton handleClick={handleModal} text="Sign Up" />
                    </div>
                    <div className='pricing_packages__package'>
                        <h1>IQLAND</h1>
                        <h1>$50<span className="pricing_packages__month">/month</span></h1>
                        <p>
                            Ideal for individual developers, real estate agents and investors.
                            All the features all the time
                        </p>
                        <ul>
                            <li>200 Parcel Searches</li>
                        </ul>
                        <IQButton handleClick={handleModal} text="Sign Up" />
                    </div>
                    <div className='pricing_packages__package'>
                        <h1>IQLAND +</h1>
                        <h1>$100<span className="pricing_packages__month">/month</span></h1>
                        <p>
                            Ideal for teams of up to 20 All the features all the time
                        </p>
                        <ul>
                            <li>500 Parcel Searches</li>
                        </ul>
                        <IQButton handleClick={handleModal} text="Sign Up" />
                    </div>
                </div>
                <h4><span>IQLAND Enterprise Solutions:</span>  For organizations of more than 20, <a href="https://www.iqland.com/" target="_blank" rel="noreferrer">contact our sales department.</a></h4>
            </div>
            <FormPopup show={showModal} handleClose={handleModal} />
        </>
    )
}

export default Pricing