import { BalanceOutlined, HomeWorkOutlined, LocalGroceryStoreOutlined, LogoutOutlined, QuestionAnswerOutlined, ReceiptLongOutlined, SmartToyOutlined, SupportAgentOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function DashboardSideBar() {

  const navigate = useNavigate();


  return (
    <div className='dashboard_sidebar'>
      <ul className='dashboard_sidebar__list'>
        <li onClick={() => navigate("/demo/chat")}><SmartToyOutlined/>Copilot</li>
        <li><HomeWorkOutlined/>Dashboard</li>
        <li className='deactive'><BalanceOutlined/>Comparable Sales</li>
        <li className='deactive'><ReceiptLongOutlined/>Your Reports</li>
        <li className='deactive'><LocalGroceryStoreOutlined/>Subscription</li>
        <li className='deactive'><QuestionAnswerOutlined/>FAQ</li>
        <li className='deactive'><SupportAgentOutlined/>Help</li>
      </ul>
      <ul className='dashboard_sidebar__list'>
        <li className='deactive'><LogoutOutlined/>Logout</li>
      </ul>
    </div>
  )
}

export default DashboardSideBar