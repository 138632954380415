import React, { useEffect, useState } from 'react';
import UserNav from '../../components/user_nav/UserNav';
import './CopilotNew.css';
import CoSideBar from './component/CoSideBar';
import CoMainArea from './component/CoMainArea';
import { getUserChatHistory } from '../../components/user_service/UserService';
import IQInformationPop from '../../components/util_component/iq_information_pop/IQInformationPop';

function CopilotNew() {
  const [titles, setTitles] = useState([]);

  const [infoPop, setInfoPop] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");


  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await getUserChatHistory();
        setTitles(response.data);
      } catch (error) {
        setInfoMsg(error);
        setInfoPop(true);
      }
    };
    
    fetchChatHistory();
  }, []); 

  const handleNewChat = (newChat) => {
    setTitles((prevTitles) => [newChat, ...prevTitles]);
  };

  return (
    <div className='copilot_new'>
      {/* Navigation  */}
      <div className='copilot_new__navigation'>
        <UserNav tab={"c"}/>
      </div>

      {/* Main area */}
      <div className='copilot_new__main'>
        {/* Copilot side bar */}
        <div className='copilot_new__sidebar'>
          <CoSideBar titles={titles} />
        </div>

        {/* Copilot chat area */}
        <div className='copilot_new__content'>
          <CoMainArea onNewChat={handleNewChat}/>
        </div>
      </div>

      {
        infoPop && <IQInformationPop message={infoMsg} setVisibility={setInfoPop}/>
      }
    </div>
  );
}

export default CopilotNew;
