import React from 'react'

function ParcelInformation() {
  return (
    <div className='parcelinformation'>
        <b>Parcel Information</b>
        <form>
            <input type='text' placeholder='Longitude / Latitude'/>
            <input type='text' placeholder='Property type: (Vacant land, SFR, etc)'/>
            <input type='text' placeholder='Lot size:'/>
            <input type='text' placeholder='Length:'/>
            <input type='text' placeholder='Width:'/>
            <input type='text' placeholder='Flood Zone: Yes / No'/>
        </form>
    </div>
  )
}

export default ParcelInformation