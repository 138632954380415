import { Alert, FormControl, FormHelperText, InputLabel, OutlinedInput, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom'
import { verifyEmail } from '../../components/auth/AuthService';
import { useLocation } from 'react-router-dom';

function NewVerifyOtp() {
    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const location = useLocation();
    const email = location.state?.email;

    const navigate = useNavigate();

    useEffect(() => {
        if (!email) {
            navigate('/register');
        }
    }, [email, navigate]);

    const handleSubmit = async (email, code) => {
        try {
            const response = await verifyEmail(email, code);
            console.log(response);

            setToastText("Email Verified Successfully, Please sign in")
            setToastStatus("success")
            setToastShow(true)
            setTimeout(() => {
                window.location.href = "/login"
            }, 2000);
        }
        catch (error) {
            console.log(error);
            setToastText("Something went wrong")
            setToastStatus("warning")
            setToastShow(true)
        }
    }

    const formik = useFormik({
        initialValues: {
            verify_code: ""
        },
        validationSchema: Yup.object({
            verify_code: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            handleSubmit(email, values.verify_code);
        },
    });


    return (
        <div className='new_login_page'>
            <div className='new_login__image_side'>
                <img src="/images/login/login_page_img.jpg" alt="login" />
            </div>

            <div className='new_login__login_form_area'>

                <form onSubmit={formik.handleSubmit} className='new_login__login_form'>

                    <img className='logo' src='/images/logo.svg' alt='logo' />
                    <div className='new_login__form_title_area'>
                        <Typography className='new_login__form_title'>Verify Your Email</Typography>
                    </div>
                    <Typography className='new_login__form_info' sx={{ maxWidth: "20rem" }}>Please enter the verification code that was sent to your email address.</Typography>
                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.verify_code && Boolean(formik.errors.verify_code)}>
                        <InputLabel htmlFor="outlined-adornment-first">Verification Code</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-first"
                            type="text"
                            label="Verification Code"
                            name="verify_code"
                            value={formik.values.verify_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.verify_code && formik.errors.verify_code ? (
                            <FormHelperText>{formik.errors.verify_code}</FormHelperText>
                        ) : null}
                    </FormControl>

                    <div className='new_login__buttons'>
                        <div className='new_login__button_area'>
                            <input type="submit" value="Verify" className='iq_primary_btn' />
                        </div>
                    </div>
                    <h6 className='new_login__cancel' onClick={() => navigate("/login")}>Cancel</h6>
                    <Typography className='new_login__resend'>Didn't receive code? <span>Resend Code</span></Typography>
                </form>


            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={6000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setToastShow(false)}
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>

        </div>
    )
}

export default NewVerifyOtp
