import React, { useState } from "react";
import "./EventSubscriptions.css";
import { DoneOutlined, InfoOutlined } from "@mui/icons-material";
import { Switch, Tooltip } from "@mui/material";
import { subscribePlan } from "../../components/auth/AuthService";
import CountdownTimer from "../../components/countdown_timer/CountdownTimer";

// 25 reports / $195 month  $10 each additional
// 50 reports / $295 month  $7.50 each additional
// 100 reports / $495 month  $5.004 each additional

const subscribe_Plan = async(plan) => {
  try{
    const res = await(subscribePlan(plan));
    window.location.href = res.data.session_url;
  }
  catch(error) {
    console.log(error);
  }
}

const plans = {
  monthly: [
    {
      id: "monthly1",
      name: "Starter",
      description:
        "Ideal for individual developers to get competitive advantage.",
      features: ["All the features all the time", "25 Parcel Searches"],
      price: "$195",
      priceDetail: "$10 each additional parcel search",
      link: "",
    },
    {
      id: "monthly2",
      name: "ADVANCED",
      description:
        "Best for small-medium sized teams that aim at boosting their business impact.",
      features: ["All the features all the time", "50 Parcel Searches"],
      price: "$295",
      priceDetail: "$7.50 each additional parcel search",
      link: "",
    },
    {
      id: "monthly3",
      name: "PREMIUM",
      description:
        "Perfect for companies that need access to unlimited growth.",
      features: ["All the features all the time", "100 Parcel Searches"],
      price: "$495",
      priceDetail: "$5 each additional parcel search",
      link: "",
    },
  ],
  yearly: [
    {
      id: "yearly1",
      name: "Starter",
      description:
        "Ideal for individual developers to get competitive advantage.",
      features: ["All the features all the time", "300 Parcel Searches"],
      price: "$185",
      priceDetail: "$10 each additional parcel search",
      link: "",
    },
    {
      id: "yearly2",
      name: "ADVANCED",
      description:
        "Best for small-medium sized teams that aim at boosting their business impact.",
      features: ["All the features all the time", "600 Parcel Searches"],
      price: "$280",
      priceDetail: "$7.50 each additional parcel search",
      link: "",
    },
    {
      id: "yearly3",
      name: "PREMIUM",
      description:
        "Perfect for companies that need access to unlimited growth.",
      features: ["All the features all the time", "1,200 Parcel Searches"],
      price: "$445",
      priceDetail: "$5 each additional parcel search",
      link: "",
    },
  ],
};

const comparison = {
  headings: ["STARTER", "ADVANCED", "PREMIUM"],
  details: [
    {
      item: "Parcel Searches included",
      col1: "25",
      col2: "50",
      col3: "100",
    },
    {
      item: "Cost per additional parcel search",
      col1: "$10.00",
      col2: "$7.50",
      col3: "$5.00",
    },
    {
      item: "Ask questions to your AI Copilot",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
      tooltip: "Intuitively interact with a copilot on any question relative to your parcels of interest (zoning, cost, design recommendations, etc.)"
    },
    {
      item: "Profitability estimation",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
      tooltip: "Run analyses and projections on your parcels of interest. Leverage our ML models to get cost, VAC and Equity estimations"
    },
    {
      item: "Architectural designs recommendations",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
      tooltip: "Recommendations are tailored with respect to the zoning regulations applicable on the selected parcel."
    },
    {
      item: "Subdivision of a lot",
      col1: "Coming Soon",
      col2: "Coming Soon",
      col3: "Coming Soon",
      tooltip: "Get optimal lot subdivision proposals on your parcel of interest."
    },
    {
      item: "Check the conformity of your plans",
      col1: "Coming Soon",
      col2: "Coming Soon",
      col3: "Coming Soon",
      tooltip: "Get feedback on your permit applications to ensure they get approved faster."
    },
    {
      item: "Parcel and Zoning information",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
    },
    {
      item: "Comparable sales",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
    },
    {
      item: "Download and Share reports of your parcel analyses",
      col1: "Yes",
      col2: "Yes",
      col3: "Yes",
    },
  ],
};

function EventSubscriptions() {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getCellContent = (content) => {
    return content === "Yes" ? (
      <DoneOutlined
        sx={{
          fontSize: "24px",
          color: "#239B0D",
        }}
      />
    ) : (
      content
    );
  };

  
  const targetDate = '2024-07-15T14:00:00Z';

  return (
    <div className="even_subs" style={{backgroundColor:"#f9f9f9"}}>
      <div className="even_subs__top_banner">
        <div className="even_subs__top_banner_texts">
          <h4>Special Offer for PlaceKeepers attendees</h4>
          <h6>Save up to 20% with COUPON CODE: PLACEKEEPERS24</h6>
        </div>
        <div>
          <CountdownTimer targetDate={targetDate}/>
        </div>
      </div>
      <div className="plans">
        <div className="plans__heading">
          Choose the plan that fits your needs
        </div>

        <div className="toggle">
          <div className="toggle__label">Monthly</div>
          <Switch
            checked={checked}
            onChange={handleChange}
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#126342",
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "rgba(18, 99, 66, 0.6)",
              },
            }}
          />
          <div className="toggle__label">Yearly</div>
        </div>

        <h6 style={{ textAlign: "center" }}>SAVE up to 10% with yearly subscriptions when paid upfront.</h6>

        <div className="plans__container">
          {plans[checked ? "yearly" : "monthly"]?.map((plan, index) => (
            <div key={plan.id} className="plan">
              <div className="plan__content">
                <div className="plan_info">
                  <div className="plan__info__name">{plan.name}</div>
                  <div className="plan__info__description">
                    {plan.description}
                  </div>
                </div>
                <div className="plan__features">
                  {plan.features?.map((feature, featureIndex) => (
                    <div
                      key={`feature-${index}-${featureIndex}`}
                      className="plan__feature"
                    >
                      <div className="plan__feature__icon">
                        <DoneOutlined
                          sx={{
                            fontSize: "14px",
                            color: "#126342",
                          }}
                        />
                      </div>
                      <div className="plan__feature__text">{feature}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="plan__price">
                <div className="plan__price_heading">
                  <div className="plan__price_heading__amount">
                    {plan.price}
                  </div>
                  <div className="plan__price_heading__duration">
                    {checked ? "/month" : "/month"}
                  </div>
                </div>
                <div className="plan__price_detail">{plan.priceDetail}</div>
              </div>
              <button onClick={() => subscribe_Plan(plan.id)} className="plan__link">
                Buy Now
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="comparison comparison_container">
        <div className="comparison__heading">Compare features and plans</div>
        <div className="comparison__table">
          <div className="table__header">
            <div className="table__first_col"></div>
            {comparison.headings.map((heading, index) => (
              <div key={`heading-${index}`} className="table__cell">
                {heading}
              </div>
            ))}
          </div>
          {comparison.details.map((detail, index) => (
            <div key={`row-${index}`} className="table__row">
              <div className="table__first_col">{detail.item} &nbsp;
                {detail.tooltip &&
                  <Tooltip title={detail.tooltip}>
                    <InfoOutlined sx={{ width: "1rem", height: "1rem" }} />
                  </Tooltip>}
              </div>
              <div className="table__cell">{getCellContent(detail.col1)}</div>
              <div className="table__cell">{getCellContent(detail.col2)}</div>
              <div className="table__cell">{getCellContent(detail.col3)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EventSubscriptions;
