import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './NewLogin.css'
import { EmailOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'

function NewForgotEmail() {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required')
        }),
        onSubmit: (values) => {
            navigate('/verify_login')
        },
    });


    return (
        <div className='new_login_page'>
            <div className='new_login__image_side'>
                <img src="/images/login/login_page_img.jpg" alt="login" />
            </div>

            <div className='new_login__login_form_area'>

                <form onSubmit={formik.handleSubmit} className='new_login__login_form'>

                    <img className='logo' src='/images/logo.svg' alt='logo' />
                    <div className='new_login__form_title_area'>
                        <h6 onClick={() => navigate('/')} className='new_login__form_title'>Forgot Email</h6>
                    </div>
                    <FormControl sx={{ width: "100%" }} variant="outlined" error={formik.touched.email && Boolean(formik.errors.email)}>
                        <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email"
                            type="email"
                            endAdornment={
                                <InputAdornment position="end">
                                    <EmailOutlined />
                                </InputAdornment>
                            }
                            label="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <FormHelperText>{formik.errors.email}</FormHelperText>
                        ) : null}
                    </FormControl>
                    
                    <div className='new_login__buttons'>
                        <div className='new_login__button_area'>
                            <input type="submit" value="Login" className='iq_primary_btn' />
                        </div>
                    </div>
                    <Typography className='new_login__resend'>Back to <span onClick={() => navigate('/login')}>Log in</span></Typography>
                </form>


            </div>
        </div>
    )
}

export default NewForgotEmail
