import React from 'react'
import './PersonalInfo.css'
import IQTextboxLabel from '../../components/util_component/iq_textbox_label/IQTextboxLabel'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import { useState } from 'react'

function PersonalInfo() {


    // temp setting
    const [text, setText] = useState('');


    return (
        <div className='personal_info'>
            <UserNav />
            <div className='personal_info__container'>
                <SettingSideBar tab={"1"}/>
                <div className='personal_info__main'>
                    <div className='personal_info__part'>
                        <h5>Edit Profile</h5>
                        <div className='personal_info__profile'>
                            <img className='profile' src='/images/default_profile.jpg' alt='profile' />
                            <img className='camera' src='/images/setting/photo_icon.svg' alt='i' />
                        </div>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='First Name' value={text} setText={() => setText()} placeholder={"First Name"}/>
                                <IQTextboxLabel label='Last Name' value={text} setText={() => setText()} placeholder={"Last Name"}/>
                            </div>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Email' value="jhone@gmail.com" disabled={true}/>
                                <IQTextboxLabel label='Phone Number' value={text} setText={() => setText()} placeholder={"First Name"}/>
                            </div>
                        </div>
                        <button className='personal_info__btn'>Update</button>
                    </div>

                    <div className='personal_info__part'>
                        <h5>Preferences & Interests</h5>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Types of Properties Interested In Name' value="Not Available" disabled={true}/>
                                <IQTextboxLabel label='Proffered Home Styles Select' value="Not Available" disabled={true}/>
                            </div>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Construction Material Preference ' value="Not Available" disabled={true}/>
                            </div>
                        </div>
                        <button className='personal_info__btn' disabled>Update</button>
                    </div>

                    <div className='personal_info__part'>
                        <h5>User Interactions & Date</h5>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Favorited / Bookmarked Listings' value="Not Available" disabled={true}/>
                            </div>
                        </div>
                        <button className='personal_info__btn' disabled>Update</button>
                    </div>

                    <div className='personal_info__part'>
                        <h5>Referral Code</h5>
                        <div className='personal_info__inputs'>
                            <div className='personal_info__inputs_sets'>
                                <IQTextboxLabel label='Membership Type' value="Not Available" disabled={true}/>
                            </div>
                        </div>
                        <button className='personal_info__btn' disabled>Update</button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default PersonalInfo