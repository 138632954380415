import React, { useEffect, useRef, useState } from 'react'
import UserNav from '../../components/user_nav/UserNav'
import './DashboardNew.css'
import MetricCard from './component/MetricCard'
import PlanNew from './component/PlanNew'
import MapWithPolygon from '../../components/google_map/MapWithPolygon'
import SalesTable from './component/SalesTable'
import ParcelDetails from './component/ParcelDetails'
import GoogleAddressInput from '../../components/google_map/GoogleAddressInput'
import NewFilter from './component/NewFilter'
import IQList from '../../components/util_component/iq_list/IQList'
import MDropDown from './component/MDropDown'
import { createReport, getArchitecturePlansOnDashboard, getComparableSales, getFavoritePlans, getKpi, getParcelInfo } from '../../components/user_service/UserService'
import IQInformationPop from '../../components/util_component/iq_information_pop/IQInformationPop'
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading'
import { ArrowDropDown } from '@mui/icons-material'
import IQTextView from '../../components/util_component/iq_text_view/IQTextView'
import MetricCardAcc from './component/MetricCardAcc'

function DashboardNew() {

    const [info, setInfo] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    // convert to currency
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    }



    // parcel data
    // map
    const [mapData, setMapData] = useState();
    const [parcelData, setParcelData] = useState();
    const [zoningData, setZoningData] = useState();
    // parcel data loading
    const [parcelLoading, setParcelLoading] = useState(false);
    // no parcel
    const [noParcelFound, setNoParcelFound] = useState(false);

    //top amounts
    const [kpi, setKpi] = useState();
    // kpi loading
    const [kpiLoading, setKpiLoading] = useState(false);


    // plans
    const [plans, setPlans] = useState();
    // plans loading
    const [plansLoading, setPlansLoading] = useState(false);


    // favoirte plans
    const [favoirtPlans, setFavoritePlans] = useState();

    // coparable sales
    const [comparableSalesData, setComparableSalesData] = useState();
    // coparable sales loading
    const [comparableSalesLoading, setComparableSalesLoading] = useState(false);
    const [noComparableSalesFound, setNoComparableSalesFound] = useState(false);

    // filter bar visibility
    const [filterBar, setFilterBar] = useState(false);
    const [showActions, setShowActions] = useState(false);

    // top floors
    const [floorCount, setFloorCount] = useState("");

    // top total area
    const [totalArea, setTotalArea] = useState("")


    // select plan state
    const [selectedPlan, setSelectedPlan] = useState({});

    // actions list
    const action = [
        {
            text: "Create new dashboard",
            action: null
        },
        {
            text: "Ask a question to copilot",
            action: null
        },
        {
            text: "Download the report",
            action: null
        },
        {
            text: "Share report by email",
            action: null
        },
        {
            text: "Save A Report",
            action: null
        }

    ]

    // handle outside click
    const actionsRef = useRef(null);
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setShowActions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // end of handle outside click


    // get favorite plans
    async function getFavoritePlansOfUser() {
        try {
            const response = await getFavoritePlans();
            setFavoritePlans(response.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    // check id in the favorite list
    function isFavorite(plan_id) {
        return favoirtPlans.some(favPlan => favPlan.id === plan_id);
    }

    // check id is selected plan
    function isSelectedPlan(plan_id) {
        return selectedPlan.id === plan_id;
    }

    // main report creation based on address
    async function createReportFun(addr) {
        try {
            const response = await createReport(addr);
            console.log("report", response);
            return response.data;
        }
        catch (err) {
            console.log("report", err.message);
        }
    }

    async function fetchParcelInfo(addr, report_id) {
        setNoParcelFound(false)
        setParcelLoading(true)
        try {
            const response = await getParcelInfo(addr, report_id);
            console.log("parcel", response);
            setMapData(response.data.map);
            setParcelData(response.data.parcel);
            setZoningData(response.data.zoning);
            setParcelLoading(false);
        }
        catch (err) {
            setNoParcelFound(true)
            setParcelLoading(false);
            console.log("parcel", err);
        }
    }

    // fetching comparable sales 
    async function comparableSales(addr, report_id) {
        setComparableSalesLoading(true);
        setNoComparableSalesFound(false);
        try {
            const response = await getComparableSales(addr, report_id);
            console.log("sales", response);
            setComparableSalesData(response.data);
            setComparableSalesLoading(false);
        }
        catch (err) {
            setNoComparableSalesFound(true);
            setComparableSalesLoading(false);
            setKpiLoading(false)
            console.log("sales", err);
        }
    }

    async function getPlans(data, report_id) {
        setPlansLoading(true)
        try {
            const response = await getArchitecturePlansOnDashboard(data, report_id);
            console.log("plans", response);
            setPlans(response.data)
            setPlansLoading(false)
            return response.data;
        }
        catch (err) {
            console.log("plans", err);
            setPlansLoading(false)
        }
    }

    async function fetchKpi(report_id, build_quality, land_acquisition_cost, address, architectural_plan_id) {
        setKpiLoading(true)
        try {
            const response = await getKpi(report_id, build_quality, land_acquisition_cost, address, architectural_plan_id);
            console.log("kpi", response);
            setKpi(response.data)
            setKpiLoading(false)
        }
        catch (err) {
            setInfo(true);
            console.log("kpi", err.message);
            setKpiLoading(false)
        }
    }

    // handle address
    const [address, setAddress] = useState("8226 E 34TH ST, Tulsa, OK");
    const [initAddress, setInitAddress] = useState("8226 E 34TH ST, Tulsa, OK");

    // handle address search on top filter
    const handleAddressSearch = async () => {
        if (address !== null && address !== undefined && address !== "") {
            setParcelLoading(true);
            setKpiLoading(true);
            setComparableSalesLoading(true);
            setInitAddress(address);
            try {
                const report = await createReportFun(address);
                await fetchParcelInfo(address, report.id);
                await comparableSales(address, report.id);
                plans && plans.length > 0 ? await fetchKpi(report.id, buildQuality, parseFloat(acquisitionCost) + parseFloat(additionalCost), address, plans[0].id) : setKpiLoading(false);
                console.log("handleAddressSearch Worked!")
            } catch (err) {
                console.log("handleAddressSearch", err.message);
            }
        } else {
            setInfoMessage("Please enter address");
            setInfo(true);
        }
    }


    // handle press enter in the textbox
    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {

            event.preventDefault();
            await handleAddressSearch();

        }
    };


    // handle build quality
    const [buildQuality, setBuildQuality] = useState("BRONZE");

    // handle request when click build quality
    const handleBuildQualityClick = (quality) => {
        setBuildQuality(quality);
        try {
            createReportFun(initAddress)
                .then((report_id) => {
                    fetchKpi(report_id.id, quality, parseFloat(acquisitionCost) + parseFloat(additionalCost), initAddress, selectedPlan.id)
                });


        } catch (err) {
            console.log(err.message);
        }
    }

    // handle Acquisition cost
    const [acquisitionCost, setAcquisitionCost] = useState(0);

    // handle Additional cost
    const [additionalCost, setAdditionalCost] = useState(0);

    // handle Acquition ok click
    const handleAcquisitionOkClick = (acu) => {
        setKpiLoading(true)
        try {
            createReportFun(initAddress)
                .then((report_id) => {
                    fetchKpi(report_id.id, buildQuality, acu, initAddress, selectedPlan.id)
                });

        } catch (err) {
            setKpiLoading(false)
            console.log(err.message);
        }
    }



    // handle filter button click
    const handleFilterButtonClick = async (body) => {
        try {
            setPlansLoading(true)
            setKpiLoading(true)
            setFilterBar(false)
            const report_id = await createReportFun(initAddress);
            const res = await getPlans(body, report_id.id)

            setTotalArea(`${body.area_total_min}-${body.area_total_max}`)
            setFloorCount(body.stories.map((item) => item.toString()))

            if (res && res.length !== 0) {
                setSelectedPlan(res[0]);
                await fetchKpi(report_id.id, buildQuality, parseFloat(acquisitionCost) + parseFloat(additionalCost), initAddress, res[0].id);
            } else {
                setKpiLoading(false)
            }

        } catch (err) {
            setInfo(true);
            console.log(err.message);
        }
    };


    //filter object
    const [filter, setFilter] = useState({
        "title": "",
        "architectural_style": ["Modern Farmhouse", "Country", "Barndominium", "New American", "Scandinavian", "Modern", "Cottage", "Contemporary", "Southern", "Acadian", "Adobe", "A-Frame", "Beach", "Bungalow", "Cabin", "Cap cod", "Carriage", "Coastal Contemporary", "Colonial", "European"],
        "area_total_min": 10,
        "area_total_max": 10000,
        "width_min": 10,
        "width_max": 500,
        "height_min": 10,
        "height_max": 100,
        "depth_min": 10,
        "depth_max": 500,
        "stories": ["1"],
        "cars_capacity": ["2"],
        "foundation": ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"],
        "exterior_wall_type": ["2x4"],
        "garage_type": ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"],
        "units": ["Single Family", "Duplex", "Multi Family", "Other"],
        "bedrooms": ["4"],
        "bathrooms": ["3"]
    });


    // get address from user
    const getDataFromSessionStorage = async () => {
        let addr = "8226 E 34TH ST, Tulsa, OK"
        if(sessionStorage.getItem("address") && sessionStorage.getItem("address") !== "NA"){
            addr = sessionStorage.getItem("address")
        }
        return addr;
    }



    useEffect(() => {
            
        try {
            getDataFromSessionStorage().then((addr) => {
                setInitAddress(addr);
                setAddress(addr);

                createReportFun(addr)
                .then((report_id) => {

                    // getting parcel information zoning, map, parcel
                    fetchParcelInfo(addr, report_id.id);

                    // get sales details
                    comparableSales(addr, report_id.id);

                    //get user's favorite plan lists
                    getFavoritePlansOfUser();

                    //get plans
                    getPlans(filter, report_id.id).then((plans) => {
                        if (plans && plans.length !== 0) {
                            setSelectedPlan(plans[0]);
                            fetchKpi(report_id.id, buildQuality, parseFloat(acquisitionCost) + parseFloat(additionalCost), addr, plans[0].id)
                        }
                    })

                    setTotalArea(`${filter.area_total_min}-${filter.area_total_max}`)
                    setFloorCount(filter.stories.map((item) => item.toString()))
                });
            })
            


        } catch (err) {
            setInfo(true);
            console.log(err.message);
        }

    }, []);


    // handle plan change
    const handlePlanChange = async (plan_id) => {
        setKpiLoading(true)
        const report = await createReportFun(initAddress);
        console.log(plan_id)
        await fetchKpi(report.id, buildQuality, parseFloat(acquisitionCost) + parseFloat(additionalCost), initAddress, plan_id);
    }


    return (
        <div className='dashboard_new' style={filterBar ? { overflowY: "hidden" } : { overflowY: "auto" }}>
            <div>
                <UserNav tab={"d"} />
            </div>
            <div className='dashboard_new__main'>

                {/* Dashboard top  filter */}
                <div className='dashboard_new__top_filter_area'>

                    <div className='dashboard_new__address_input'>
                        <GoogleAddressInput
                            setAddress={setAddress}
                            address={address}
                            handleKeyPress={handleKeyPress}
                        />
                        <div onClick={handleAddressSearch} className='keyword_search_btn'>
                            <img src='/images/dashboard_new/search_icon.svg' alt='filter' />
                        </div>
                    </div>


                    <div className='dashboard_new__top_filter'>

                        <MDropDown
                            label={"Build Quality"}
                            values={["BRONZE", "SILVER", "GOLD"]}
                            texts={["Economy", "Standard", "Premium"]}
                            value={buildQuality}
                            setValue={handleBuildQualityClick}
                        />

                        <IQTextView label={"Total Area (sqft)"} text={totalArea} handleClick={() => setFilterBar(true)} />
                        <IQTextView label={"Floors"} text={floorCount} handleClick={() => setFilterBar(true)} />

                        <div onClick={() => setFilterBar(true)} className='dashboard_new__more_filter_btn'>
                            <img src="/images/dashboard_new/more_filter_icon.svg" alt='more' />
                            <div>Filters</div>
                        </div>
                    </div>
                </div>

                {/* Address and action button */}
                <div className='dashboard_new__address_area'>

                    {/* address */}
                    <h6 className='dashboard_new_address'>{initAddress}</h6>

                    {/* action button */}
                    <div className='dashboard_new__actions'>
                        <div ref={actionsRef} className='dashboard_new__actions_button' onClick={() => setShowActions(!showActions)}>
                            <div>Actions</div>
                            <ArrowDropDown />
                        </div>
                        {
                            showActions && <IQList items={action} />
                        }
                    </div>

                </div>

                {/* Top cards */}
                <div className='metric_cards'>
                    {
                        kpi && !plansLoading ?
                            <MetricCard
                                icon={"vac_icon"}
                                icon_back={"#D1AD71"}
                                icon_alt={"vac"}
                                title={"Equity"}
                                tt_text={"Equity represents the residual interest in a property after deducting liabilities, offering insight into the potential financial gain or advantage of the project"}
                                amount={!noComparableSalesFound && plans.length > 0 ? formatCurrency(kpi.equity) : "-"}
                                increased={true}
                                // increase_per={"21.9%"}
                                color={"second"}
                                loading={kpiLoading}
                            />
                            : <LoadingSkeleton />

                    }
                    {kpi && !plansLoading ?
                        <MetricCard
                            icon={"equity_icon"}
                            icon_back={"#1263421A"}
                            icon_alt={"vac"}
                            title={"Value after Completion"}
                            tt_text={"Our algorithm generates a predictive range for the potential value after completion, offering users a well-informed projection to aid in decision-making processes and future planning"}
                            amount={!noComparableSalesFound && plans.length > 0 ? formatCurrency(kpi.vac) : "-"}
                            increased={true}
                            loading={kpiLoading}
                            // increase_per={"21.9%"}
                            // increase_amount={"+$64K today"}
                            color={"gray"}
                        /> : <LoadingSkeleton />
                    }
                    {
                        kpi && !plansLoading ?
                            <MetricCard
                                icon={"tpc_icon"}
                                icon_back={"#1263421A"}
                                icon_alt={"vac"}
                                title={"Total Project Cost"}
                                tt_text={"The Total Project Costs is a pivotal metric to determine the cumulative expenses you can expect for the entirety of the construction and land acquisition phase"}
                                amount={plans.length > 0 ? formatCurrency(kpi.total_project_cost) : "-"}
                                increased={true}
                                // increase_per={"21.9%"}
                                color={"gray"}
                                loading={kpiLoading}
                            />
                            : <LoadingSkeleton />
                    }

                    {
                        kpi && !plansLoading ? <MetricCard
                            icon={"cc_icon"}
                            icon_back={"#1263421A"}
                            icon_alt={"vac"}
                            title={"Construction Cost"}
                            tt_text={"Our Construction Costs computation provides users with an insightful estimate for building a home based on their selected plan. This estimate is drawn from localized construction data, ensuring accurate, region-specific cost projections"}
                            amount={plans.length > 0 ? formatCurrency(kpi.construction_cost) : "-"}
                            increased={true}
                            loading={kpiLoading}
                            // increase_per={"21.9%"}
                            color={"gray"} />
                            : <LoadingSkeleton />
                    }
                    
                    {
                        kpi && !plansLoading ?
                            <MetricCardAcc
                                icon={"tpc_icon"}
                                icon_back={"#1263421A"}
                                icon_alt={"vac"}
                                title={"Equity"}
                                tt_text={"Equity represents the residual interest in a property after deducting liabilities, offering insight into the potential financial gain or advantage of the project"}
                                amount={acquisitionCost}
                                setAmount={setAcquisitionCost}
                                adcAmount={additionalCost}
                                setAdcAmount={setAdditionalCost}
                                fetchKpi={handleAcquisitionOkClick}
                                color={"gray"}
                                loading={kpiLoading}
                            />
                            : <LoadingSkeleton />

                    }


                </div>
                {
                    plans && favoirtPlans && selectedPlan && !plansLoading ?
                        <div className='dashboard_new__plans_area'>
                            <h6 className='dashboard_new__title'>Plans Found: {plans.length}</h6>
                            <div className='dashboard_new__plans'>
                                {
                                    plans.map((plan, index) => (
                                        <PlanNew key={index} plan={plan} fav={isFavorite(plan.id)} selectedPlan={isSelectedPlan(plan.id)} selectPlan={setSelectedPlan} handleClick={() => handlePlanChange(plan.id)} />
                                    ))
                                }
                            </div>
                        </div>

                        : <LoadingSkeleton width={"100%"} />
                }

                <div className='dashboard_new__parcel_area'>
                    <div className='dashboard_new__parcel_map'>
                        <h6 style={{ marginBottom: "1rem" }}>{!noParcelFound ? "Parcel Map" : "Parcel Details"}</h6>
                        {!noParcelFound && (mapData && !parcelLoading ? <MapWithPolygon polygon={mapData} /> : <LoadingSkeleton />)}
                        {noParcelFound && <div style={{ width: "100%" }}><div>No Parcel Data</div></div>}
                    </div>

                    {
                        !noParcelFound && <div className='dashboard_new__parcel_details'>
                            {parcelData && zoningData && !parcelLoading ? <ParcelDetails parcelInfo={parcelData} zoningInfo={zoningData} /> : <LoadingSkeleton />}
                        </div>
                    }

                </div>
                <div className='dashboard_new__comparable_sales'>
                    <h6>Comparable Sales</h6>
                    {
                        !noComparableSalesFound && (comparableSalesData && !comparableSalesLoading ? <SalesTable rows={comparableSalesData} /> : <LoadingSkeleton width={"100%"} />)

                    }
                    {noComparableSalesFound && <div style={{ width: "100%" }}><div>No Comparable Sales Data</div></div>}
                </div>
            </div>

            {
                filterBar &&
                <div className='filter_bar'>
                    <NewFilter
                        handleClose={() => setFilterBar(false)}
                        filterData={filter}
                        setFilterData={setFilter}
                        cost={acquisitionCost}
                        setCost={setAcquisitionCost}
                        handleFilterButton={handleFilterButtonClick}
                    />
                </div>
            }

            {
                filterBar && <div onClick={() => setFilterBar(false)} className='filter_overflow'>
                    &nbsp;
                </div>
            }

            {/* {
                loading && <IQLoading message={"Setting up dashboard"} />
            } */}
            {
                info && <IQInformationPop message={infoMessage} setVisibility={setInfo} />
            }
        </div>
    )
}

export default DashboardNew