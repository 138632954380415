import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import './CopilotLand.css';
import NavBar from '../../components/navbar/NavBar';
import FormPopup from '../../components/modal/FormPopup';
function CopilotLand() {

    const queryList = [
        "average building cost per square foot in this area?",
        "most cost-effective architectural plan available for a residential project on this parcel?",
        "zoning classification for my property and its implications on development potential?",
        "estimated value of the development upon completion based on current market trends?",

        "determine the best price to list a developed property based on its location and features?",
        "find architectural plans that match my client's specific needs and budget constraints?",
        "access up-to-date zoning information to advise clients on potential development opportunities?",
        "estimate the construction costs for a property renovation to help clients budget for their project?",

        "setbacks of the site plan to ensure compliance with local zoning requirements.",
        "floor to area ratio (FAR) provided in the construction plans against our jurisdiction's limits.",
        "lot coverage calculations in the submitted plans to verify adherence to permitted development densities.",
        "proposed building height and setbacks from the construction plans to ensure they meet our city's planning standards.",

        "the best construction plans for maximum ROI for this residential parcel.",
        "the ideal square footage for homes in this neighborhood.",
        "a modern home plan that meets the zoning requirements for this parcel.",
        "a 3 story Tudor style home for my parcel.",

        "build a single-family home with an accessory dwelling unit (ADU) on this parcel?",
        "increase the floor area ratio (FAR) to add more living space to the existing structure on this parcel?",
        "subdivide this lot to accommodate more than 1 house?",
        "build a multifamily home on this parcel?"
    ];


    const [currentQueryIndex, setCurrentQueryIndex] = useState(0);
    const [queryText, setQueryText] = useState("");
    const [currentTitle, setCurrentTitle] = useState("What is the...");

    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const currentQuery = queryList[currentQueryIndex];
            let index = 0;
            const innerInterval = setInterval(() => {
                if (index <= currentQuery.length) {
                    setQueryText(currentQuery.substring(0, index));
                    index++;
                } else {
                    clearInterval(innerInterval);
                    setCurrentQueryIndex((prevIndex) => (prevIndex + 1) % queryList.length);
                    setTimeout(() => {

                    }, 1000);
                }
            }, 50);

            if (queryText === queryList[3]) {
                setCurrentTitle("How can I...");
            }
            else if (queryText === queryList[7]) {
                setCurrentTitle("Analyze the...");
            }
            else if (queryText === queryList[11]) {
                setCurrentTitle("Recommend ...");
            }
            else if (queryText === queryList[15]) {
                setCurrentTitle("Can I ...");
            }
            else if (queryText === queryList[19]) {
                setCurrentTitle("What is the...");
            }

            return () => clearInterval(innerInterval);
        }, 1000);

        return () => clearInterval(interval);
    },);





    return (
        <>
            <NavBar handleVisiblity={handleModal} />
            <div className="hero-container">
                <div>
                    <Row>
                        <Col md={6}>
                            <div className="left-column ">
                                <div className='center-sm'>
                                    <div>
                                        <h1>{currentTitle}</h1>
                                    </div>
                                    <div className="typed-text">
                                        <p>{queryText}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="right-column">
                                <div>
                                    <h1>Land intelligence done. <span>Fast.</span></h1>
                                    <p className="parah">IQLAND is an AI powered copilot created for investors,
                                        developers, agents and cities to quickly, efficiently and accurately evaluate
                                        real estate development projects.</p>
                                    <div className='cto-section'>
                                        <p className="action-text">Join the waitlist</p>
                                        <Button variant="success" onClick={handleModal}>Join</Button>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <FormPopup show={showModal} handleClose={handleModal} />
        </>
    );
}

export default CopilotLand;
