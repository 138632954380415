import React, { useState } from 'react'
import './SecuritySetting.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import IQTextboxLabel from '../../components/util_component/iq_textbox_label/IQTextboxLabel'

function SecuritySetting() {

    const [text, setText] = useState("")

  return (
    <div className='security_setting'>
       <UserNav/>
            <div className='security_setting__container'>
                <SettingSideBar tab={"4"} />
                <div className='security_setting__main'>
                    <div className='security_setting__part'>
                        <h5>Security & Password</h5>
                        
                        <div className='security_setting__inputs'>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='New Password' value={text} setText={() => setText()} placeholder={"First Name"} />
                                <IQTextboxLabel label='Confirm New Password' value={text} setText={() => setText()} placeholder={"Last Name"} />
                            </div>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Notification Preferences' value="jhone@gmail.com" disabled={true} />
                                <IQTextboxLabel label='Email Subscription Setting' value={text} setText={() => setText()} placeholder={"First Name"} />
                            </div>
                        </div>
                        <button className='security_setting__btn'>Update</button>
                    </div>

                    <div className='security_setting__part'>
                        <h5>2 Factor Authentication </h5>
                        <div className='security_setting__inputs'>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Membership Date' value="Not Available" disabled={true} />
                                <IQTextboxLabel label='Proffered Home Styles Select' value="Not Available" disabled={true} />
                            </div>
                            <div className='security_setting__inputs_sets'>
                                <IQTextboxLabel label='Security Questions ' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='security_setting__btn' disabled>Update</button>
                    </div>

                </div>
            </div> 
    </div>
  )
}

export default SecuritySetting