import React from 'react'
import './CoSingleChat.css'
import { DashboardCustomize } from '@mui/icons-material'
import TypeWriter from '../../../components/type_writer/TypeWriter'
import { useNavigate } from 'react-router-dom'
import GoogleAddressInput from '../../../components/google_map/GoogleAddressInput'

function CoSingleChat({ chat, isLastMessage, missingInput }) {

    const navigate = useNavigate();
    // console.log(isLastMessage);
    return (
        <div className='co_single_chat'>
            {/* user chat */}
            {
                chat.sent_by === "User" ?
                    <div className='co_single_chat__user_chat'>
                        <div className='co_single_chat__user_profile'>
                            <img src="/images/default_profile.jpg" alt='user profile' />
                            {/* <h5>You</h5> */}
                        </div>
                        <div className='co_single_chat__user_message'>
                            {chat.text}
                        </div>

                    </div>
                    :
                    /* bot chat */
                    < div className='co_single_chat__bot_chat'>
                        <div className='co_single_chat__bot_profile'>
                            <img src='/images/copilot_new/iq_profile_image.svg' alt='iqland profile' />
                        </div>
                        <div className='co_single_chat__bot_message'>
                            <div className='co_single_chat__answer_area'>

                                <div className='co_single_chat__answer_text'>
                                    <TypeWriter text={chat.text} isNewMessage={isLastMessage} />
                                </div>
                            </div>
                            <div className='co_single_chat__populate_button'>
                                <DashboardCustomize style={{ fontSize: "16px" }} />
                                <h6 onClick={() => navigate("/dashboard")}>Populate Dashboard</h6>
                            </div>
                        </div>
                    </div>
            }

        </div >
    )
}

export default CoSingleChat