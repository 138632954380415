import React, { useState } from 'react'
import './GeoDetails.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import IQTextboxLabel from '../../components/util_component/iq_textbox_label/IQTextboxLabel'

function GeoDetails() {


    const [text, setText] = useState('');

    return (
        <div className='geo_details'>
            <UserNav />
            <div className='geo_details__container'>
                <SettingSideBar tab={"3"} />
                <div className='geo_details__main'>
                    <div className='geo_details__part'>
                        <h5>Geographic Details</h5>
                        
                        <div className='geo_details__inputs'>
                            <div className='geo_details__inputs_sets'>
                                <IQTextboxLabel label='Primary Service Area/City' value={text} setText={() => setText()} placeholder={"First Name"} />
                                <IQTextboxLabel label='State' value={text} setText={() => setText()} placeholder={"Last Name"} />
                            </div>
                            <div className='geo_details__inputs_sets'>
                                <IQTextboxLabel label='Province ' value="jhone@gmail.com" disabled={true} />
                                <IQTextboxLabel label='Zip Postal code' value={text} setText={() => setText()} placeholder={"First Name"} />
                            </div>
                        </div>
                        <button className='geo_details__btn'>Update</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GeoDetails