import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import CopilotLand from './pages/copilot_land/CopilotLand';
import MunicipalityDemo from './pages/municipality/MunicipalityDemo';
import MunicipalityDemoTab from './pages/municipality/MunicipalityDemoTab';
import CommercialDemo from './pages/commercial_demo/CommercialDemo';
import Pricing from './pages/pricing/Pricing';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import RegisterMain from './pages/register_main/RegisterMain';
import TestPage from './pages/test_page/TestPage';
import { AuthProvider } from './components/auth/AuthenticationContext';
import { AuthenticationRoute, LoggedOutRoute } from './components/auth/AuthRoute';
import CopilotNew from './pages/copilot_new/CopilotNew';
import OnBoarding from './pages/onboarding/OnBoarding';
import DashboardNew from './pages/dashboard_new/DashboardNew';
import CoReports from './pages/co_reports/CoReports';
import Favorites from './pages/favorites/Favorites';
import PersonalInfo from './pages/settings/PersonalInfo';
import ProfInfo from './pages/settings/ProfInfo';
import GeoDetails from './pages/settings/GeoDetails';
import SecuritySetting from './pages/settings/SecuritySetting';
import BillingSetting from './pages/settings/BillingSetting';
import SocialSetting from './pages/settings/SocialSetting';
import NotFoundPage from './pages/not_found_page/NotFoundPage';
import NewLogin from './pages/login/NewLogin';
import NewRegister from './pages/register/NewRegister';
import NewVerifyOtp from './pages/login/NewVerifyOtp';
import NewForgotEmail from './pages/login/NewForgotPassword';
import NewLoginOtp from './pages/login/NewLoginOtp';
import EventSubscriptions from './pages/event_subscriptions/EventSubscriptions';
import { CancelledPurchase, SuccessfullyPurchased } from './pages/event_subscriptions/ResponsePage';



function App() {

  return (
    <AuthProvider>
      <Router>

        <Routes>
          <Route element={<AuthenticationRoute />}>
            <Route path="/demo/dashboard" element={<Dashboard />} />
            <Route path="/demo/chat" element={<CommercialDemo />} />

            <Route path="/copilot" element={<CopilotNew />} />
            <Route path='/copilot?chat={chat_id}' element={<CopilotNew />} />
            <Route path='/dashboard' element={<DashboardNew />} />
            <Route path='/reports' element={<CoReports />} />
            <Route path='/favorite' element={<Favorites />} />

            {/* user setting tabs */}
            <Route path="/settings/personal" element={<PersonalInfo />} />
            <Route path="/settings/professional" element={<ProfInfo />} />
            <Route path="/settings/geographic" element={<GeoDetails />} />
            <Route path="/settings/security" element={<SecuritySetting />} />
            <Route path="/settings/billing" element={<BillingSetting />} />
            <Route path="/settings/social" element={<SocialSetting />} />

            <Route path="/demo/municipalities/new" element={<MunicipalityDemo />} />
            <Route path="/demo/municipalities" element={<MunicipalityDemoTab />} />
            <Route path="/demo/municipalities?tabs={tab_name}" element={<MunicipalityDemoTab />} />
          </Route>

          <Route path="/" element={<CopilotLand />} />
          <Route path="/products/copilot" element={<CopilotLand />} />
          <Route path="/pricing" element={<Pricing />} />

          <Route element={<LoggedOutRoute />}>
            <Route path="/login" element={<NewLogin />} />
            <Route path="/register_with_email" element={<Register />} />
            <Route path="/register" element={<NewRegister />} />
            <Route path="/verify_email" element={<NewVerifyOtp />} />
            <Route path='/verify_login' element={<NewLoginOtp />} />
            <Route path="/forgot_password" element={<NewForgotEmail />} />
          </Route>


          <Route path="/test" element={<TestPage />} />
          <Route path='*' element={<NotFoundPage />} />
          <Route path="/onboarding" element={<OnBoarding />} />
          <Route path="/event_subscriptions" element={<EventSubscriptions />} />
          <Route path='/event/checkout/success' element={<SuccessfullyPurchased/>}/>


        </Routes>
      </Router>
    </AuthProvider>
  )

}

export default App;
