import React from 'react'
import './CommercialDemoChat.css'
import { DashboardCustomize } from '@mui/icons-material'
import TypeWriter from '../../../components/type_writer/TypeWriter'

function CommercialDemoChat({ userText, botResponse }) {





    return (
        <div className='commercial_demo_chat'>
            {/* user chat */}
            <div className='commercial_demo_chat__user_chat'>
                <div className='commercial_demo_chat__user_profile'>
                    <img src="/images/default_profile.jpg" alt='user profile' />
                    {/* <h5>You</h5> */}
                </div>
                <div className='commercial_demo_chat__user_message'>
                    {userText}
                </div>

            </div>
            {/* bot chat */}
            <div className='commercial_demo_chat__bot_chat'>
                <div className='commercial_demo_chat__bot_profile'>
                    <img src='/iq_ic.png' alt='iqland profile' />
                    {/* <h5>IQLAND</h5> */}
                </div>
                <div className='commercial_demo_chat__bot_message'>
                    <div className='commercial_demo_chat__answer_area'>
                        {/* <div className='commercial_demo_chat__answer_icon_area'>
                            <RecordVoiceOver />
                            <h6>Answer</h6>
                        </div> */}
                        
                        <div className='commercial_demo_chat__answer_text'>
                            <TypeWriter text={botResponse.answer} isNewMessage={true}/>
                        </div>
                        <div className='commercial_demo_chat__sources'>
                            {
                                botResponse.source.map((source, index) => (
                                    
                                    <div key={index} className='commercial_demo_chat__source'>
                                        <img src={'/images/commercial_demo/municode_icon.png'} alt='source icon' />
                                        <a href={source.source} target='_blank' rel="noopener noreferrer" style={{textDecoration:"none", color:"var(--text-clr)"}}><h6>{source.name}</h6></a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='commercial_demo_chat__populate_button'>
                        <DashboardCustomize style={{fontSize:"16px"}}/>
                        <h6>Populate Dashboard</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommercialDemoChat