import React, { useState } from 'react'
import './SocialSetting.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import IQTextboxLabel from '../../components/util_component/iq_textbox_label/IQTextboxLabel'

function SocialSetting() {
    const [text, setText] = useState("")
    return (
        <div className='social_setting'>
            <UserNav />
            <div className='social_setting__container'>
                <SettingSideBar tab={"6"} />
                <div className='social_setting__main'>
                    <div className='social_setting__part'>
                        <h5>Social Media & Website</h5>
                        
                        <div className='social_setting__inputs'>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Website URL' value={text} setText={() => setText()} placeholder={"First Name"} />
                                <IQTextboxLabel label='LinkedIn Profile' value={text} setText={() => setText()} placeholder={"Last Name"} />
                            </div>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Instagram Profile' value="jhone@gmail.com" disabled={true} />
                                <IQTextboxLabel label='Twitter Profile' value={text} setText={() => setText()} placeholder={"First Name"} />
                            </div>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Facebook Page' value="jhone@gmail.com" disabled={true} />
                                
                            </div>
                        </div>
                        <button className='social_setting__btn'>Update</button>
                    </div>

                    <div className='social_setting__part'>
                        <h5>Preferences & Interests</h5>
                        <div className='social_setting__inputs'>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Types of Properties Interested In Name' value="Not Available" disabled={true} />
                                <IQTextboxLabel label='Proffered Home Styles Select' value="Not Available" disabled={true} />
                            </div>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Construction Material Preference ' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='social_setting__btn' disabled>Update</button>
                    </div>

                    <div className='social_setting__part'>
                        <h5>User Interactions & Date</h5>
                        <div className='social_setting__inputs'>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Favorited / Bookmarked Listings' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='social_setting__btn' disabled>Update</button>
                    </div>

                    <div className='social_setting__part'>
                        <h5>Referral Code</h5>
                        <div className='social_setting__inputs'>
                            <div className='social_setting__inputs_sets'>
                                <IQTextboxLabel label='Membership Type' value="Not Available" disabled={true} />
                            </div>
                        </div>
                        <button className='social_setting__btn' disabled>Update</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SocialSetting