import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthenticationContext'; // Adjust the import path as necessary

export const AuthenticationRoute = () => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div style={{width:"100vw", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <img style={{width:"10rem", }} src="/iq_gif.gif" alt="loading..."/></div>; // Or any loading component you prefer
  }

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export const LoggedOutRoute = () => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // Or any loading component you prefer
  }

  return !user ? <Outlet /> : <Navigate to="/" />;
};
