import React from 'react'

function ZoningSummary({data}) {
  return (
    <div className='zoningsummary'>
        <b>Zoning Summary</b>
        <ul className='zoningsummary__list'>
            <li><a href={data.zoning_url} target='_blank' rel="noreferrer" style={{textDecoration:"none", color:"var(--text-clr)"}}>Municode</a></li>
            <li>Parcel ID: 14-484859659696</li>
            <li>Zoning classification: {data.zoning_category}</li>
            <li>Zoning code: {data.zoning_classification}</li>
            <li>Floor to Area Ratio (FAR): {data.zoning_far} </li>
        </ul>
    </div>
  )
}

export default ZoningSummary