import React from 'react'
import SinglePlanCard from './SinglePlanCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';



function PlanFoundCard({ plans }) {
  return (
    <div className='planfoundcard'>
      {/* <SinglePlanCard/> */}
      <h4>Plans found: {plans.length}</h4>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow]}
      >
        {
          plans.slice(0,15).map((plan, index) => (
            <SwiperSlide key={index} style={{ width: "256px", height: "256px" }}>
              <SinglePlanCard  plan={plan}/>
            </SwiperSlide>
          ))
        }
       
      </Swiper>
    </div>
  )
}

export default PlanFoundCard;